import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ElevationActions from './elevation.actions';
import { Elevation } from '../../models/elevation';


export interface State extends EntityState<Elevation> {
  selectedId: string | undefined;
}

export const adapter: EntityAdapter<Elevation> = createEntityAdapter<Elevation>();

export const initialState: State = adapter.getInitialState({selectedId: undefined});

const elevationReducer = createReducer(
  initialState,
  on(ElevationActions.loadElevations, (state) => {
    return state;
  }),
  on(ElevationActions.loadElevationsSuccess, (state, { elevations }) => {
    return adapter.setAll(elevations, state);
  }),
  on(ElevationActions.loadElevationsFailure, (state, { error }) => {
    return state;
  }),
  on(ElevationActions.addElevation, (state) => {
    return state;
  }),
  on(ElevationActions.addElevationSuccess, (state, { elevation }) => {
    return adapter.setOne(elevation, state);
  }),
  on(ElevationActions.addElevationFailure, (state, { error }) => {
    return state;
  }),
  on(ElevationActions.updateElevation, (state) => {
    return state;
  }),
  on(ElevationActions.updateElevationSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(ElevationActions.updateElevationFailure, (state) => {
    return state;
  }),
  on(ElevationActions.deleteElevation, (state) => {
    return state;
  }),
  on(ElevationActions.deleteElevationSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(ElevationActions.deleteElevationFailure, (state, { error }) => {
    return state;
  }),
  on(ElevationActions.clearAllElevations, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(ElevationActions.selectElevation, (state, {id}) => {
    return { ...state, selectedId: id };
  }),
  on(ElevationActions.setElevationShow, (state, {id, show}) => {
    return adapter.updateOne({id: id, changes: {show : show}}, state);
  }),
  on(ElevationActions.addElevations, (state) => {
    return state;
  }),
  on(ElevationActions.addElevationsSuccess, (state, { elevations }) => {
    return adapter.setAll(elevations, state);
  }),
  on(ElevationActions.addElevationsFailure, (state, { error }) => {
    return state;
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return elevationReducer(state, action);
}

export const getSelectedElevationId = (state: State) => state.selectedId;
