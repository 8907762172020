import {
  autoserialize,
  autoserializeAs,
  deserialize,
  deserializeAs, serialize
} from 'cerialize';
import { Emergency } from './emergency';


export class DetectionBox {
  @autoserialize roll!: number;
  @autoserialize center!: Array<number>;
  @autoserialize dimensions!: Array<number>;
  @autoserializeAs('rotation_matrix') rotationMatrix!: Array<Array<number>>;
}

export class Detection {
  kind: string = 'detection';
  checked: boolean = true;
  @serialize name!: string;
  @autoserialize id!: string;
  @autoserialize pool!: string;
  @autoserialize label!: string;
  @autoserialize resource!: string;
  @autoserialize status!: number;
  @autoserialize confidence!: number;
  @autoserialize evolution!: number;
  @autoserializeAs(Emergency, 'emergency') emergency!: Emergency;
  @autoserializeAs('create_mode') createMode!: number;
  @autoserializeAs('coords') coords!: Array<Array<number>>;
  @autoserializeAs(DetectionBox) box!: DetectionBox;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserializeAs('bounding_box') boundingBox!: Array<number>;
  @deserialize length!: number;
  border!: string;
  backgroundColor!: string;
  textColor!: string;
  show: boolean;

  constructor() {
    this.show = true;
  }
}
