import { Injectable } from '@angular/core';
import { CesiumTools } from '../tools/cesium.tools';
import { DataSourceType } from '../enums/datasource-type';
import { Deserialize } from 'cerialize';
import { Elevation } from '../models/elevation';
import { Marker } from '../models/marker';
import { Cut } from '../models/cut';
import { Detection } from '../models/detection';
import { Volume } from '../models/volume';
import { TranslocoService } from '@jsverse/transloco';
import { map } from 'rxjs/operators';
import { ColorTools } from '../tools/color.tools';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class CesiumService {
  constructor(private store: Store, private translocoService: TranslocoService) {
  }

  entitiesInPolygon(viewer: any, polygonPositions: any, volumeStep: number): any {
    return {
      'elevations': CesiumTools.entitiesInPolygon(CesiumTools.getOrCreateDataSource(viewer, DataSourceType.ELEVATION), polygonPositions).map((e:any) => Deserialize(e.elevation, Elevation)),
      'markers': CesiumTools.entitiesInPolygon(CesiumTools.getOrCreateDataSource(viewer, DataSourceType.MARKER), polygonPositions).map((e:any) => Deserialize(e.marker, Marker)),
      'cuts': CesiumTools.entitiesInPolygon(CesiumTools.getOrCreateDataSource(viewer, DataSourceType.CUT), polygonPositions).map((e:any) => Deserialize(e.cut, Cut)),
      'detections': CesiumTools.entitiesInPolygon(CesiumTools.getOrCreateDataSource(viewer, DataSourceType.DETECTION), polygonPositions).map((e:any) => {
        const obj: Detection = Deserialize(e.detection, Detection);
        obj.name = this.translocoService.translate(e.detection.label) + " " + obj.pool.slice(0,4).toUpperCase();
        return obj;
      }),
      'volumes': ColorTools.setColorsForVolumes(CesiumTools.entitiesInPolygon(CesiumTools.getOrCreateDataSource(viewer, DataSourceType.VOLUME), polygonPositions).map((e:any) => {
        const obj = Deserialize(e.volume, Volume);
        obj.name = this.translocoService.translate('volume') + " " + obj.id.slice(0,4).toUpperCase();
        return obj;
      }), volumeStep, this.store)
    }
  }

  entities(viewer: any, volumeStep: number): any {
    return {
      'elevations': CesiumTools.getOrCreateDataSource(viewer, DataSourceType.ELEVATION).entities.values.map((e:any) => Deserialize(e.elevation, Elevation)),
      'markers': CesiumTools.getOrCreateDataSource(viewer, DataSourceType.MARKER).entities.values.map((e:any) => Deserialize(e.marker, Marker)),
      'cuts': CesiumTools.getOrCreateDataSource(viewer, DataSourceType.CUT).entities.values.map((e:any) => Deserialize(e.cut, Cut)),
      'detections': CesiumTools.getOrCreateDataSource(viewer, DataSourceType.DETECTION).entities.values.map((e:any) => {
        const obj: Detection = Deserialize(e.detection, Detection);
        obj.name = this.translocoService.translate(e.detection.label) + " " + obj.pool.slice(0,4).toUpperCase();
        return obj;
      }),
      'volumes': ColorTools.setColorsForVolumes(CesiumTools.getOrCreateDataSource(viewer, DataSourceType.VOLUME).entities.values.map((e:any) => {
        const obj = Deserialize(e.volume, Volume);
        obj.name = this.translocoService.translate('volume') + " " + obj.id.slice(0,4).toUpperCase();
        return obj;
      }), volumeStep, this.store),
    }
  }
}
