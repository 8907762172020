import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectVolumeColorMode, selectVolumeEmergencyTemplate,
} from '../../../features/volume/volume.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import { VolumeColorMode } from '../../../enums/volume';
import {
  selectConfigColorRampMax,
  selectConfigColorRampMin,
  selectConfigColorRampNegativeColors,
  selectConfigColorRampPositiveColors
} from '../../../features/config/config.selectors';
import { combineLatest } from 'rxjs';
import { Color } from '../../../enums/color';
import { TranslocoService } from '@jsverse/transloco';
import {
  EmergencyTemplate,
  EmergencyTemplateLabel
} from '../../../models/emergency-template';
import { EmergencyLevel } from '../../../enums/emergency';

@Component({
  selector: 'volume-color-ramp',
  templateUrl: './volume-color-ramp.component.html',
  styleUrls: ['./volume-color-ramp.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ bottom: '-400px'}),
        query('@*', animateChild(), { optional: true }),
        animate('350ms ease-out', style({ bottom: '0'})),
      ]),
      transition(':leave', [
        style({ bottom: '25px'}),
        animate('300ms ease-in', style({ bottom: '-400px'}))
      ]),
    ]),
  ],
})
export class VolumeColorRampComponent implements AfterViewInit {

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement> | undefined;
  labelMin: string | undefined;
  labelMax: string | undefined;
  private negativeColors: Array<string> = [];
  private positiveColors: Array<string> = [];
  private volumeMin: number = 0;
  private volumeMax: number = 0;
  emergencyTemplate: EmergencyTemplate | undefined;
  selectedVolumeColorMode: VolumeColorMode = VolumeColorMode.COLOR_MODE_VOLUME_MOVED;

  constructor(private store: Store, private translocoService: TranslocoService) {
    this.initHandleConfig();
    this.initHandleVolumeColorMode();
  }

  private initHandleVolumeColorMode() {
    this.store.select(selectVolumeColorMode)
      .pipe(takeUntilDestroyed())
      .subscribe((colorMode: VolumeColorMode) => {
        this.selectedVolumeColorMode = colorMode;
        this.draw();
      });
  }

  private initHandleConfig() {
    combineLatest([
      this.store.select(selectConfigColorRampNegativeColors),
      this.store.select(selectConfigColorRampPositiveColors),
      this.store.select(selectConfigColorRampMin),
      this.store.select(selectConfigColorRampMax),
      this.store.select(selectVolumeEmergencyTemplate),
    ]).pipe(takeUntilDestroyed())
      .subscribe((results) => {
        this.negativeColors = (results[0]) ? results[0] : [];
        this.positiveColors = (results[1]) ? results[1] : [];
        this.volumeMin = (results[2]) ? results[2] : 0;
        this.volumeMax = (results[3]) ? results[3] : 0;
        this.emergencyTemplate = results[4];
        this.draw();
      });
  }

  private generateGradient(colors: Array<string>): void {
    if(this.canvas) {
      const ctx = this.canvas.nativeElement.getContext('2d');
      if (ctx) {
        const range = colors.length;
        const d = (height:number) => height / range;
        const width = this.canvas.nativeElement.width;
        const height = this.canvas.nativeElement.height;
        const grd = ctx.createLinearGradient(0, 0, width, 0);
        colors.forEach((color: string, index:number) => {
          grd.addColorStop(d(index), color);
        });
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, width, height);
      }
    }
  }

  private generateSeparatedColors(colors: Array<string>): void {
    if(this.canvas) {
      const ctx = this.canvas.nativeElement.getContext('2d');
      if (ctx) {
        const width = this.canvas.nativeElement.width;
        const height = this.canvas.nativeElement.height;
        const colorWidth = width / colors.length;
        colors.forEach((color: string, index:number) => {
          ctx.fillStyle = color;
          ctx.fillRect(index*colorWidth, 0 , colorWidth, height);
        });
      }
    }
  }

  private clearCanvas(): void {
    if(this.canvas) {
      const ctx = this.canvas.nativeElement.getContext('2d')
      if (ctx) {
        const width = this.canvas.nativeElement.width;
        const height = this.canvas.nativeElement.height;
        ctx.clearRect(0, 0, width, height);
      }
    }
  }

  private draw(): void {
    this.clearCanvas();
    if (this.selectedVolumeColorMode == VolumeColorMode.COLOR_MODE_VOLUME_MOVED) {
      const colors = this.negativeColors.concat(['#ffffff'], this.positiveColors);
      this.labelMin = Math.floor(this.volumeMin).toFixed(0) + ' m3';
      this.labelMax = Math.ceil(this.volumeMax).toFixed(0) + ' m3';
      this.generateGradient(colors);
    }else {
      let colors = ['#ffffff'];
      if (this.emergencyTemplate) {
        const unique = [...new Map(this.emergencyTemplate.labels.map(item =>
          [item['level'], item])).values()];
        unique
          .sort((a, b) => a.level > b.level ? -1 : a.level < b.level ? 1 : 0)
          .forEach((label: EmergencyTemplateLabel) => {
          if (label.level == EmergencyLevel.HIGH) colors.push(Color.EMERGENCY_HIGH);
          if (label.level == EmergencyLevel.MEDIUM_HIGH) colors.push(Color.EMERGENCY_MEDIUM_HIGH);
          if (label.level == EmergencyLevel.MEDIUM_LOW) colors.push(Color.EMERGENCY_MEDIUM_LOW);
          if (label.level == EmergencyLevel.LOW) colors.push(Color.EMERGENCY_LOW);
        });
      }
      this.labelMin = this.translocoService.translate('not_defined');
      this.labelMax = this.translocoService.translate('urgent');
      this.generateSeparatedColors(colors);
    }
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  get volumeColorMode(): typeof VolumeColorMode {
    return VolumeColorMode;
  }
}
