import {
  autoserialize,
  autoserializeAs, deserialize, deserializeAs,
} from 'cerialize';


export class CutBatchData {
  @autoserialize batch!: string;
  @autoserializeAs("x_values") xValues!: Array<number>;
  @autoserializeAs("y_values") yValues!: Array<number>;
  @autoserializeAs("date_acquired") dateAcquired!: string;

  get dataset(): Array<any> {
    return this.xValues
      .map((value: number, index: number) => {
        return [value, this.yValues[index]];
      });
  }
}

export class Cut {
  kind: string = 'cut';
  checked: boolean = true;
  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize author!: string;
  @autoserialize point1!: Array<number>;
  @autoserialize point2!: Array<number>;
  @autoserializeAs(CutBatchData) data!: Array<CutBatchData>;
  @autoserialize slope!: Array<Array<number>>;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserialize length!: number;
  @deserializeAs('is_shared') isShared!: boolean;
  show: boolean;

  constructor() {
    this.show = true;
  }
}

