<mat-card *ngIf="measure"
          class="card-measure"
          [style.border]=border
          (click)="onClickEdit(measure)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{measure.name}}</mat-card-title>
    <mat-card-subtitle *ngIf="measure.straightMeasure">{{'straight_line_distance' | transloco}}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="measure.areaVolume">{{'area_and_volume' | transloco}}</mat-card-subtitle>
  </mat-card-header>

  <div  *ngIf="(currentUser$ | async) as currentUser" class="shared-item-indicators">
    <mat-icon *ngIf="!measure.show">visibility_off</mat-icon>
    <mat-icon *ngIf="measure.isShared && currentUser.name == measure.author">person</mat-icon>
    <mat-icon *ngIf="measure.isShared">share</mat-icon>
  </div>
</mat-card>



