import { createAction, props } from '@ngrx/store';
import { Elevation } from '../../models/elevation';
import { Update } from '@ngrx/entity';
import { Comment } from '../../models/comment';

export const loadElevations = createAction('[Elevation/API] Load Elevations ', props<{ organizationId: string, siteId: string}>());
export const loadElevationsSuccess = createAction('[Elevation/API] Load Elevations success', props<{ elevations: Elevation[] }>());
export const loadElevationsFailure = createAction('[Elevation/API] Load Elevations failure', props<{ error: any }>());

export const addElevation = createAction('[Elevation] Add Elevation ', props<{ organizationId: string, siteId: string, point: Array<number>}>());
export const addElevationSuccess = createAction('[Elevation/API] Add Elevation success', props<{ elevation: Elevation}>());
export const addElevationFailure = createAction('[Elevation/API] Add Elevation failure', props<{ error: any }>());

export const updateElevation = createAction('[Elevation/API] Update Elevation ', props<{ organizationId: string, siteId: string, elevationId: string, payload: any }>());
export const updateElevationSuccess = createAction('[Elevation/API] Update Elevation success', props<{ update: Update<any> }>());
export const updateElevationFailure = createAction('[Elevation/API] Update Elevation failure', props<{ error: any }>());

export const deleteElevation = createAction('[Elevation] Delete Elevation', props<{ organizationId: string, siteId: string, elevationId: string }>());
export const deleteElevationSuccess = createAction('[Elevation/API] Delete Elevation success', props<{ id: string }>());
export const deleteElevationFailure = createAction('[Elevation/API] Delete Elevation failure', props<{ error: any }>());

export const clearAllElevations = createAction('[Elevation] Clear All Elevations');
export const selectElevation= createAction('[Elevation] Select Elevation', props<{ id: string | undefined}>());

export const loadElevationComments = createAction('[Elevation/API] Load Elevation Comments ', props<{ organizationId: string, siteId: string, elevationId: string  }>());
export const loadElevationCommentsSuccess = createAction('[Elevation/API] Load Elevation Comments success', props<{ comments: Array<Comment> }>());
export const loadElevationCommentsFailure = createAction('[Elevation/API] Load Elevation Comments failure', props<{ error: any }>());

export const addElevationComment = createAction('[Elevation/API] Add Elevation Comment ', props<{ organizationId: string, siteId: string, elevationId: string, payload: any }>());
export const addElevationCommentSuccess = createAction('[Elevation/API] Add Elevation Comment success', props<{ comment: Comment }>());
export const addElevationCommentFailure = createAction('[Elevation/API] Add Elevation Comment failure', props<{ error: any }>());

export const setElevationShow = createAction('[Elevation] Set Elevation Show property', props<{ id: string, show: boolean}>());

export const addElevations = createAction('[Elevation/API] Add Elevations', props<{ organizationId: string, siteId: string, elevations: any }>());
export const addElevationsSuccess = createAction('[Elevation/API] Add Elevations success', props<{ elevations: Array<Elevation> }>());
export const addElevationsFailure = createAction('[Elevation/API] Add Elevations failure', props<{ error: any }>());
