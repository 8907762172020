import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Batch } from '../../../models/batch';
import { selectAllBatchs } from '../../../features/batch/batch.selectors';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { selectUser } from '../../../features/user/user.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '../../../models/user';


@Component({
  selector: 'batch-diff-representation-selector-dialog',
  templateUrl: './batch-diff-representation-selector.dialog.html',
  styleUrls: ['../dialog.scss', './batch-diff-representation-selector.dialog.scss'],
})
export class BatchDiffRepresentationSelectorDialog implements AfterViewInit {
  icon: string | undefined;
  title: string = '';
  subtitle: string | undefined;
  message: string = '';
  batchComparedId: string | undefined;
  batchReferenceId: string | undefined;

  batchComparedControl = new FormControl('');
  batchReferenceControl = new FormControl('');
  private user: User | null | undefined;

  constructor(private store: Store, public dialogRef: MatDialogRef<BatchDiffRepresentationSelectorDialog>) {
    this.initHandleUser();
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      });
  }

  get batchs$(): Observable<Array<Batch>> {
    return this.store.select(selectAllBatchs);
  }

  get dateFormat() {
    if(this.user) {
      return (this.user.languageCode == 'fr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    }
    return 'MM/dd/yyyy';
  }

  get isDisabled() {
    return true;
  }

  ngAfterViewInit(): void {
    this.batchComparedControl.setValue(this.batchComparedId || '');
    this.batchReferenceControl.setValue(this.batchReferenceId || '');
    this.batchComparedControl.disable();
    this.batchReferenceControl.disable();
  }
}
