import {
  Directive, ElementRef, Input, OnInit,
} from '@angular/core';


@Directive({
  selector: '[split-view-slider]'
})
export class SplitViewSliderDirective implements OnInit {

  isActive: boolean = false;
  handler: any;
  @Input({required: true}) scene: any;

  constructor(private el: ElementRef)  {

  }

  get splitPosition(): number {
    if (this.el.nativeElement && this.el.nativeElement.parentElement) {
      return this.el.nativeElement.offsetLeft / this.el.nativeElement.parentElement.offsetWidth
    }
    return 0.0;
  }

  ngOnInit(): void {
    this.scene.splitPosition = this.splitPosition;
    this.handler = new Cesium.ScreenSpaceEventHandler(this.el.nativeElement);
    this.handler.setInputAction(() => {
      this.isActive = true;
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

    this.handler.setInputAction(() =>  {
      this.isActive = false;
    }, Cesium.ScreenSpaceEventType.LEFT_UP);

    this.handler.setInputAction((movement:any) => {
      if (!this.isActive) {
        return;
      }

      const relativeOffset = movement.endPosition.x;
      const splitPosition = (this.el.nativeElement.offsetLeft + relativeOffset) / this.el.nativeElement.parentElement.offsetWidth;
      this.el.nativeElement.style.left = `${100.0 * splitPosition}%`;
      this.scene.splitPosition = this.splitPosition;
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

}
