import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { setMapHandleKeyboard } from '../../../features/cesium/cesium.actions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Site } from '../../../models/site';
import { loadVolumes } from '../../../features/volume/volume.actions';
import { Observable } from 'rxjs';
import { selectVolumeTotal } from '../../../features/volume/volume.selectors';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectCurrentReferenceBatch } from '../../../features/batch/batch.selectors';
import { Batch } from '../../../models/batch';

@Component({
  selector: 'volume-threshold-form',
  templateUrl: './volume-threshold-form.component.html',
  styleUrls: ['./volume-threshold-form.component.scss'],
})
export class VolumeThresholdFormComponent {

  site: Site | null | undefined;
  referenceBatch: Batch | null | undefined;
  volumeForm = new FormGroup({
    threshold: new FormControl<number>(1, [
      Validators.required,
      Validators.min(0.01),
    ]),
    step: new FormControl<number>(1, [
      Validators.required,
      Validators.min(0.01),
    ]),
  });

  constructor(private store: Store) {
    this.initHandleSite();
    this.initHandleBatch();
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        this.site = site;
      });
  }

  private initHandleBatch(): void {
    this.store.select(selectCurrentReferenceBatch)
      .pipe(takeUntilDestroyed())
      .subscribe((batch: Batch | null | undefined) => {
        this.referenceBatch = batch;
      });
  }

  onInputHandleFocus(event: any) {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }
  onInputLeaveFocus(event: any) {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  loadVolumes(): void {
    if(!this.site || !this.volumeForm.valid || !this.referenceBatch) return;
    const threshold = this.volumeForm.controls.threshold.value || 1;
    const step = this.volumeForm.controls.step.value || 1;
    this.store.dispatch(loadVolumes({
      organizationId: this.site.organization,
      siteId: this.site.id,
      comparedBatchId: this.site.lastBatch,
      referenceBatchId: this.referenceBatch.id,
      threshold,
      step: step
    }));
  }

  get volumeCount$(): Observable<number> {
    return this.store.select(selectVolumeTotal);
  }
}
