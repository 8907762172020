import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromConfig from './config.reducer';


export const selectConfigState = createFeatureSelector<fromConfig.State>('config');

export const selectConfigSideMode = createSelector(
  selectConfigState,
  state => state.sideMode
);

export const selectConfigDisplaySitesFilters = createSelector(
  selectConfigState,
  state => state.displaySitesFilters
);

export const selectConfigSitesFilters = createSelector(
  selectConfigState,
  state => state.sitesFilters
);

export const selectConfigColorRampPositiveColors = createSelector(
  selectConfigState,
  state => state.colorRampPositiveColors
);
export const selectConfigColorRampNegativeColors = createSelector(
  selectConfigState,
  state => state.colorRampNegativeColors
);
export const selectConfigColorRampMin = createSelector(
  selectConfigState,
  state => state.colorRampMin
);
export const selectConfigColorRampMax = createSelector(
  selectConfigState,
  state => state.colorRampMax
);
export const selectConfigSiteDetailSelectedSection = createSelector(
  selectConfigState,
  state => state.siteDetailSelectedSection
);
export const selectConfigSearchWorldText = createSelector(
  selectConfigState,
  state => state.searchWorldText
);
export const selectConfigSearchBarDisplayed = createSelector(
  selectConfigState,
  state => state.searchBarDisplayed
);
export const selectConfigTilesetStyleColorConditions = createSelector(
  selectConfigState,
  state => state.tilesetStyleColorConditions
);
export const selectConfigTilesetColorRampMin = createSelector(
  selectConfigState,
  state => state.tilesetColorRampMin
);
export const selectConfigTilesetColorRampMax = createSelector(
  selectConfigState,
  state => state.tilesetColorRampMax
);
export const selectConfigViewRepresentation = createSelector(
  selectConfigState,
  state => state.viewRepresentation
);
export const selectTilesetIsLoading = createSelector(
  selectConfigState,
  state => state.tilesetIsLoading
);
export const selectSplittedTilesetIsLoading = createSelector(
  selectConfigState,
  state => state.splittedTilesetIsLoading
);
