import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Site } from '../../../models/site';
import { deactivateSite, } from '../../../features/site/site.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  SiteDeactivationDialog
} from '../../../shared/dialogs/site-deactivation-dialog/site-deactivation.dialog';
import {
  ReportModeDialog
} from '../../../shared/dialogs/report-mode-dialog/report-mode.dialog';
import { ReportMode } from '../../../enums/report';
import {
  generateReport,
  generateReportFailure,
  generateReportSuccess,
  reportRequireAllData,
  reportWithData
} from '../../../features/report/report.actions';
import { setDrawingMode } from '../../../features/cesium/cesium.actions';
import { DrawingMode } from '../../../enums/drawing-mode';
import {
  ReportDialog
} from '../../../shared/dialogs/report-dialog/report.dialog';
import { combineLatest, first } from 'rxjs';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import {
  selectConfigColorRampMax,
  selectConfigColorRampMin,
  selectConfigColorRampNegativeColors,
  selectConfigColorRampPositiveColors
} from '../../../features/config/config.selectors';
import {
  selectAllVolumes,
  selectVolumeEmergencyTemplate
} from '../../../features/volume/volume.selectors';
import {
  selectAllMeasures
} from '../../../features/measure/measure.selectors';
import { selectAllCuts } from '../../../features/cut/cut.selectors';
import { selectAllMarkers } from '../../../features/marker/marker.selectors';
import {
  selectAllElevations
} from '../../../features/elevation/elevation.selectors';
import {
  selectAllDetections
} from '../../../features/detection/detection.selectors';
import { EmergencyTemplateLabel } from '../../../models/emergency-template';
import { EmergencyLevel } from '../../../enums/emergency';
import { Color } from '../../../enums/color';
import {
  InspectionReport,
  InspectionReportDetection,
  InspectionReportSettings,
  InspectionReportVolume
} from '../../../models/inspection-report';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import {
  retrieveDenseCloudDownloadUrl,
  retrieveDenseCloudDownloadUrlFailure,
  retrieveDenseCloudDownloadUrlSuccess,
  retrieveSparseCloudDownloadUrl,
  retrieveSparseCloudDownloadUrlFailure,
  retrieveSparseCloudDownloadUrlSuccess
} from '../../../features/batch/batch.actions';
import { ExportTools } from '../../../tools/export.tools';
import { User } from '../../../models/user';
import { DatePipe } from '@angular/common';
import { selectUser } from '../../../features/user/user.selectors';
import { UploadType } from '../../../enums/upload';
import { FileTools } from '../../../tools/file.tools';
import { addMarkers } from '../../../features/marker/marker.actions';
import { addElevations } from '../../../features/elevation/elevation.actions';
import { addCuts } from '../../../features/cut/cut.actions';

@Component({
  selector: 'site-actions-bar',
  templateUrl: './site-actions-bar.component.html',
  styleUrls: ['./site-actions-bar.component.scss']
})
export class SiteActionsBarComponent {

  @Input({required: true}) site: Site | undefined
  @ViewChild('fileUpload') fileUploadRef: ElementRef<HTMLInputElement> | undefined;
  private uploadType: UploadType = UploadType.NONE;

  constructor(private store: Store,
              private actions: Actions,
              private dialog: MatDialog,
              private translocoService: TranslocoService,
              private toastr: ToastrService) {
    this.initHandleReport();
    this.initHandleDownload();
  }

  private initHandleDownload(): void {
    this.actions.pipe(ofType(retrieveSparseCloudDownloadUrlSuccess), takeUntilDestroyed())
      .subscribe(data => {
        ExportTools.exportResourceUrl(data.url);
      });
    this.actions.pipe(ofType(retrieveDenseCloudDownloadUrlSuccess), takeUntilDestroyed())
      .subscribe(data => {
        ExportTools.exportResourceUrl(data.url);
      });
    this.actions.pipe(ofType(retrieveSparseCloudDownloadUrlFailure, retrieveDenseCloudDownloadUrlFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.error(this.translocoService.translate('process_error'));
      });
  }

  private initHandleReport(): void {
    this.actions.pipe(ofType(reportWithData), takeUntilDestroyed())
      .subscribe(data => {
        this.report(data.data);
      });
    this.actions.pipe(ofType(generateReportSuccess), takeUntilDestroyed())
      .subscribe(data => {
        this.toastr.success(this.translocoService.translate('generate_report_success', {mail: data.email}));
      });
    this.actions.pipe(ofType(generateReportFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.error(this.translocoService.translate('generate_report_failure'));
      });
  }

  onClickDeactivateSite(site: Site) {
    const deactivateSiteDialogRef = this.dialog.open(SiteDeactivationDialog);
    deactivateSiteDialogRef.componentInstance.siteName = site.name;
    deactivateSiteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.dispatch(deactivateSite({
          organizationId: site.organization,
          siteId: site.id
        }));
      }
    });
  }

  onFileSelected(event: any): void {
    const file:File = event.target.files[0];
    if (file) {
      let data;
      switch (this.uploadType) {
        case UploadType.CSV_CUT:
          file.text().then((content: any) => {
            data = FileTools.cvsFileCutValidator(content);
            if (data && this.site) {
              this.store.dispatch(addCuts({
                organizationId: this.site.organization,
                siteId: this.site.id,
                cuts: data
              }));
            }else {
              this.toastr.error(this.translocoService.translate('upload_invalid_csv_content'));
            }
          });
          break;
        case UploadType.CSV_ELEVATION:
          file.text().then((content: any) => {
            data = FileTools.cvsFileElevationValidator(content);
            if (data && this.site) {
              this.store.dispatch(addElevations({
                organizationId: this.site.organization,
                siteId: this.site.id,
                elevations: data
              }));
            }else {
              this.toastr.error(this.translocoService.translate('upload_invalid_csv_content'));
            }
          });

          break;
        case UploadType.CSV_MARKER:
          file.text().then((content: any) => {
            data = FileTools.cvsFileMarkerValidator(content);
            if (data && this.site) {
              this.store.dispatch(addMarkers({
                organizationId: this.site.organization,
                siteId: this.site.id,
                markers: data
              }));
            }else {
              this.toastr.error(this.translocoService.translate('upload_invalid_csv_content'));
            }
          });
          break;
      }
    }
  }

  onClickUploadCSVCut(site: Site): void {
    this.uploadType = UploadType.CSV_CUT;
    this.openFileChooser("text/csv");
  }

  onClickUploadCSVElevation(site: Site): void {
    this.uploadType = UploadType.CSV_ELEVATION;
    this.openFileChooser("text/csv");
  }

  onClickUploadCSVMarker(site: Site): void {
    this.uploadType = UploadType.CSV_MARKER;
    this.openFileChooser("text/csv");
  }

  onClickDownloadPointCloudDense(site: Site) {
    this.store.select(selectUser)
      .pipe(first())
      .subscribe((user: User | null | undefined) => {
        if (this.site && user) {
          const dateFormat = user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
          const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
          const date = datePipe.transform(new Date(this.site.lastBatchDate), dateFormat)
          const filename = this.site.name.replace(/ /g, "_") + '_' + date + '_dense_cloud.ply';
          this.store.dispatch(retrieveDenseCloudDownloadUrl({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            filename
          }));
        }
      });
  }

  onClickDownloadPointCloudSparse(site: Site) {
    this.store.select(selectUser)
      .pipe(first())
      .subscribe((user: User | null | undefined) => {
        if (this.site && user) {
          const dateFormat = user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
          const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
          const date = datePipe.transform(new Date(this.site.lastBatchDate), dateFormat)
          const filename = this.site.name.replace(/ /g, "_") + '_' + date + '_sparse_cloud.ply';
          this.store.dispatch(retrieveSparseCloudDownloadUrl({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            filename
          }));
        }
      });
  }


  selectReportMode(): void {
    const dialogReportModeRef = this.dialog.open(ReportModeDialog, {
      disableClose: false
    });
    dialogReportModeRef.afterClosed().subscribe(mode => {
      if (mode == ReportMode.ALL) {
        this.store.dispatch(reportRequireAllData());
      }
      else if (mode == ReportMode.AREA) {
        this.store.dispatch(setDrawingMode({drawingMode: DrawingMode.REPORT_AREA}));
      }
    });
  }

  report(data: any): void {
    const dialogReportRef = this.dialog.open(ReportDialog, {
      disableClose: false
    });
    dialogReportRef.componentInstance.data = data;
    dialogReportRef.afterClosed().subscribe(formats => {
      if (formats) {
        this.generateReports(formats);
      }
    });
  }

  private generateReports(formats: Array<string>): void {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectConfigColorRampPositiveColors),
      this.store.select(selectConfigColorRampNegativeColors),
      this.store.select(selectConfigColorRampMin),
      this.store.select(selectConfigColorRampMax),
      this.store.select(selectVolumeEmergencyTemplate),
      this.store.select(selectAllMeasures),
      this.store.select(selectAllCuts),
      this.store.select(selectAllMarkers),
      this.store.select(selectAllElevations),
      this.store.select(selectAllVolumes),
      this.store.select(selectAllDetections),
    ]).pipe(first())
      .subscribe(results => {
        const site: Site | null | undefined = results[0];
        if(site) {
          const emergencyColors = ['#ffffff'];
          if (results[5]) {
            const unique = [...new Map(results[5].labels.map(item =>
              [item['level'], item])).values()];
            unique
              .sort((a, b) => a.level > b.level ? -1 : a.level < b.level ? 1 : 0)
              .forEach((label: EmergencyTemplateLabel) => {
                if (label.level == EmergencyLevel.HIGH) emergencyColors.push(Color.EMERGENCY_HIGH);
                if (label.level == EmergencyLevel.MEDIUM_HIGH) emergencyColors.push(Color.EMERGENCY_MEDIUM_HIGH);
                if (label.level == EmergencyLevel.MEDIUM_LOW) emergencyColors.push(Color.EMERGENCY_MEDIUM_LOW);
                if (label.level == EmergencyLevel.LOW) emergencyColors.push(Color.EMERGENCY_LOW);
              });
          }

          const settings = InspectionReportSettings.with(
            formats,
            results[1],
            results[2],
            results[3],
            results[4],
            emergencyColors);

          const report = new InspectionReport();
          report.settings = settings;
          report.measures = results[6];
          report.cuts = results[7];
          report.markers = results[8];
          report.elevations = results[9];
          report.volumes = InspectionReportVolume.fromVolumes(results[10]);
          report.detections = InspectionReportDetection.fromDetections(results[11]);

          this.store.dispatch(generateReport({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            payload: report
          }));
        }
      });
  }

  private openFileChooser(accept: string) {
    this.fileUploadRef?.nativeElement.setAttribute('accept',"text/csv" );
    this.fileUploadRef?.nativeElement.click();
  }
}
