import {
  AfterViewInit,
  Component,
  computed,
  signal,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Cut } from '../../../models/cut';
import { Elevation } from '../../../models/elevation';
import { Marker } from '../../../models/marker';
import { Measure } from '../../../models/measure';
import { Volume } from '../../../models/volume';
import { Detection } from '../../../models/detection';
import { MatStepper } from '@angular/material/stepper';


export interface SubTask {
  name: string;
  extension: string;
  completed: boolean;
  subtasks?: Task[];
}

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: SubTask[];
}

@Component({
  selector: 'report-dialog',
  templateUrl: './report.dialog.html',
  styleUrls: ['../dialog.scss', './report.dialog.scss']
})
export class ReportDialog implements AfterViewInit {

  data: any = {};
  isLinear = false;
  availableReportTypes: Array<any> = [
    {name: 'report', type: 'report', checked: false},
    {name: 'cutting_book', type: 'cutting_book', checked: false},
  ];
  availableReportFormats: Array<any> = [
    {name: 'PDF', extension: 'pdf', checked: false},
    {name: 'Docx', extension: 'docx', checked: false},
    {name: 'LibreOffice/OpenOffice', extension: 'odt', checked: false}
  ];

  readonly globalReportTask = signal<Task>({
    name: 'global_report',
    completed: false,
    subtasks: [
      {name: 'PDF', extension: 'pdf', completed: false},
      {name: 'Docx', extension: 'docx', completed: false},
      {name: 'LibreOffice/OpenOffice', extension: 'odt', completed: false},
    ],
  });

  readonly globalReportTaskPartiallyComplete = computed(() => {
    const task = this.globalReportTask();
    if (!task.subtasks) {
      return false;
    }
    return task.subtasks.some((t: any) => t.completed) && !task.subtasks.every(t => t.completed);
  });

  update(completed: boolean, index?: number) {
    this.globalReportTask.update(task => {
      if (index === undefined) {
        task.completed = completed;
        task.subtasks?.forEach(t => (t.completed = completed));
      } else {
        task.subtasks![index].completed = completed;
        task.completed = task.subtasks?.every((t: any) => t.completed) ?? true;
      }
      return {...task};
    });
  }


  @ViewChild('stepper') stepper!: MatStepper;

  constructor(public dialogRef: MatDialogRef<ReportDialog>) {}

  onClickNext(): void {
    this.stepper.next();
  }

  onClickBack(): void {
    this.stepper.previous();
  }

  get cuts(): Array<Cut> {
    return (this.data['cuts']) ? this.data['cuts'] : []
  }
  get selectedCuts(): Array<Cut> {
    return (this.data['cuts']) ? this.data['cuts'].filter((o: Cut) => o.checked) : []
  }

  get elevations(): Array<Elevation> {
    return (this.data['elevations']) ? this.data['elevations'] : []
  }
  get selectedElevations(): Array<Elevation> {
    return (this.data['elevations']) ? this.data['elevations'].filter((o: Elevation) => o.checked) : []
  }

  get markers(): Array<Marker> {
    return (this.data['markers']) ? this.data['markers'] : []
  }
  get selectedMarkers(): Array<Marker> {
    return (this.data['markers']) ? this.data['markers'].filter((o: Marker) => o.checked) : []
  }

  get measures(): Array<Measure> {
    return (this.data['measures']) ? this.data['measures'] : []
  }
  get selectedMeasures(): Array<Measure> {
    return (this.data['measures']) ? this.data['measures'].filter((o: Measure) => o.checked) : []
  }

  get volumes(): Array<Volume> {
    return (this.data['volumes']) ? this.data['volumes'] : []
  }
  get selectedVolumes(): Array<Volume> {
    return (this.data['volumes']) ? this.data['volumes'].filter((o: Volume) => o.checked) : []
  }

  get detections(): Array<Detection> {
    return (this.data['detections']) ? this.data['detections'] : []
  }
  get selectedDetections(): Array<Detection> {
    return (this.data['detections']) ? this.data['detections'].filter((o: Detection) => o.checked) : []
  }

  ngAfterViewInit(): void {
    this.stepper.animationDuration = "0";
  }

}
