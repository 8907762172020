import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as BatchActions from './batch.actions';
import { Deserialize } from 'cerialize';
import { Batch } from '../../models/batch';
import { ApiService } from '../../services/api.service';
import { retrieveDenseCloudDownloadUrl } from './batch.actions';


@Injectable()
export class BatchEffects {

  loadBatchs$ = createEffect(() => {
    return this.actions$.pipe(ofType(BatchActions.loadBatchs),
      concatMap(({organizationId, siteId}) =>
      this.api.batchs$(organizationId, siteId).pipe(
        map(batchs => batchs.map((batch: any) => Deserialize(batch, Batch))),
        map(batchs => BatchActions.loadBatchsSuccess({ batchs })),
        catchError(error => of(BatchActions.loadBatchsFailure({ error }))))
    ));
  });


  retrieveDenseCloudDownloadUrl$ = createEffect(() => {
    return this.actions$.pipe(ofType(BatchActions.retrieveDenseCloudDownloadUrl),
      concatMap(({organizationId, siteId, batchId, filename}) =>
        this.api.pointCloudDenseUrl$(organizationId, siteId, batchId, filename).pipe(
          map(url => BatchActions.retrieveDenseCloudDownloadUrlSuccess({ url })),
          catchError(error => of(BatchActions.retrieveDenseCloudDownloadUrlFailure({ error }))))
      ));
  });

  retrieveSparseCloudDownloadUrl$ = createEffect(() => {
    return this.actions$.pipe(ofType(BatchActions.retrieveSparseCloudDownloadUrl),
      concatMap(({organizationId, siteId, batchId, filename}) =>
        this.api.pointCloudSparseUrl$(organizationId, siteId, batchId, filename).pipe(
          map(url => BatchActions.retrieveSparseCloudDownloadUrlSuccess({ url })),
          catchError(error => of(BatchActions.retrieveSparseCloudDownloadUrlFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions, private api: ApiService) {}

}
