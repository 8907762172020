import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

@Component({
  selector: 'create-perread-pipeline-dialog',
  templateUrl: './create-perread-pipeline.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class CreatePerreadPipelineDialog {

  icon: string | undefined;
  title: string = '';
  subtitle: string | undefined;
  message: string = '';

  constructor(private store: Store, public dialogRef: MatDialogRef<CreatePerreadPipelineDialog>) {

  }

}
