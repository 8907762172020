import { createAction, props } from '@ngrx/store';
import { Batch } from '../../models/batch';

export const loadBatchs = createAction('[Batch/API] Load Batchs ', props<{ organizationId: string, siteId: string }>());
export const loadBatchsSuccess = createAction('[Batch/API] Load Batchs success', props<{ batchs: Batch[] }>());
export const loadBatchsFailure = createAction('[Batch/API] Load Batchs failure', props<{ error: any }>());

export const clearAllBatchs = createAction('[Batch] Clear All Batch');
export const selectReferenceBatch = createAction('[Cut] Select Reference Batch', props<{ id: string | undefined}>());


export const retrieveDenseCloudDownloadUrl = createAction('[Batch/API] Retrieve Dense Cloud Download Url ', props<{ organizationId: string, siteId: string, batchId: string, filename: string }>());
export const retrieveDenseCloudDownloadUrlSuccess = createAction('[Batch/API] Retrieve Dense Cloud Download Url success', props<{ url: string }>());
export const retrieveDenseCloudDownloadUrlFailure = createAction('[Batch/API] Retrieve Dense Cloud Download Url failure', props<{ error: any }>());

export const retrieveSparseCloudDownloadUrl = createAction('[Batch/API] Retrieve Sparse Cloud Download Url ', props<{ organizationId: string, siteId: string, batchId: string, filename: string }>());
export const retrieveSparseCloudDownloadUrlSuccess = createAction('[Batch/API] Retrieve Sparse Cloud Download Url success', props<{ url: string }>());
export const retrieveSparseCloudDownloadUrlFailure = createAction('[Batch/API] Retrieve Sparse Cloud Download Url failure', props<{ error: any }>());
