import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromBatch from './batch.reducer';
import { getSelectedReferenceBatchId } from './batch.reducer';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromBatch.adapter.getSelectors();

export const selectBatchState = createFeatureSelector<fromBatch.State>('batchs');

export const selectBatchIds = createSelector(
  selectBatchState,
  selectIds
);
export const selectBatchEntities = createSelector(
  selectBatchState,
  selectEntities
);
export const selectAllBatchs = createSelector(
  selectBatchState,
  selectAll
);
export const selectBatchTotal = createSelector(
  selectBatchState,
  selectTotal
);

export const selectCurrentReferenceBatchId = createSelector(
  selectBatchState,
  fromBatch.getSelectedReferenceBatchId
);

export const selectCurrentReferenceBatch= createSelector(
  selectBatchEntities,
  selectCurrentReferenceBatchId,
  (entities, id) => (id) ? entities[id] : null
);
