<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>rocket_launch</mat-icon>&nbsp;{{'create_perread_pipeline_dialog_title' | transloco}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="content">
      <p>{{'create_perread_pipeline_dialog_message' | transloco}}</p>
      <p>{{'create_perread_pipeline_dialog_message_note' | transloco}}</p>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close()">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(true)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
