import { environment } from '../../environments/environment';
import { Site } from '../models/site';


export class SiteTools {
  static longitude(site: Site): number {
    if(site.pin) {
      const position = Cesium.Cartesian3.fromArray(site.pin);
      const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
      return Cesium.Math.toDegrees(carto.longitude);
    }
    return 0;
  }

  static latitude(site: Site): number {
    if(site.pin) {
      const position = Cesium.Cartesian3.fromArray(site.pin);
      const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
      return Cesium.Math.toDegrees(carto.latitude);
    }
    return 0;
  }

  static mainTilesUrl(site: Site): string | undefined {
    if(site.lastBatch) {
      return environment.tilesUrl + '/' + site.lastBatch + '/main/tileset.json';
    }
    return undefined;
  }

  static pointCloudTilesUrl(site: Site): string | undefined {
    if(site.lastBatch) {
      return environment.tilesUrl + '/' + site.lastBatch + '/point_cloud/tileset.json';
    }
    return undefined;
  }

}
