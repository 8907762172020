import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ReportMode } from '../../../enums/report';


@Component({
  selector: 'report-mode-dialog',
  templateUrl: './report-mode.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class ReportModeDialog {

  availableMode: Array<any> = [
    {name: 'report_mode_all', value: ReportMode.ALL},
    {name: 'report_mode_area', value: ReportMode.AREA},
  ];
  reportModeControl = new FormControl(ReportMode.ALL);

  constructor(public dialogRef: MatDialogRef<ReportModeDialog>) {}

}
