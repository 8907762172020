import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { getUser, getUserSuccess } from './features/user/user.actions';
import { selectUser } from './features/user/user.selectors';
import { first, Observable } from 'rxjs';
import { User } from './models/user';
import {
  loadOrganizations,
  loadOrganizationsFailure,
  loadOrganizationsSuccess,
  selectOrganization
} from './features/organization/organization.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  selectAllOrganizations,
  selectCurrentOrganization,
  selectOrganizationTotal
} from './features/organization/organization.selectors';
import { Title } from '@angular/platform-browser';
import { Organization } from './models/organization';
import { Router } from '@angular/router';
import { loadSectors } from './features/sector/sector.actions';
import { Actions, ofType } from '@ngrx/effects';
import {
  activateSiteFailure,
  activateSiteSuccess,
  deactivateSite,
  deactivateSiteFailure,
  deactivateSiteSuccess,
  loadSites,
  selectSite
} from './features/site/site.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  UserConfigurationErrorDialog
} from './shared/dialogs/user-configuration-error-dialog/user-configuration-error.dialog';
import {
  selectConfigDisplaySitesFilters,
  selectConfigSideMode
} from './features/config/config.selectors';
import { ConfigSideMode } from './enums/config';
import { setConfigSideMode } from './features/config/config.actions';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { selectCurrentSite } from './features/site/site.selectors';
import { Site } from './models/site';
import { SiteService } from './services/site.service';
import { ConfirmDialog } from './shared/dialogs/confirm-dialog/confirm.dialog';
import {
  OrganizationDialog
} from './shared/dialogs/organization-dialog/organization.dialog';
import { TranslocoService } from '@jsverse/transloco';
import {
  SiteDeactivationDialog
} from './shared/dialogs/site-deactivation-dialog/site-deactivation.dialog';
import {
  startPerreadPipelineFailure,
  startPerreadPipelineSuccess
} from './features/pipeline/pipeline.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('sideExpandedCollpased', [
      state('expanded', style({
        width: '300px'
      })),
      state('collapsed', style({
        width: '56px'
      })),
      transition('expanded => collapsed', [
        animate('150ms ease-out'),
      ]),
      transition('collapsed => expanded', [
        animate('150ms ease-out'),
      ])
    ]),
  ],
})
export class AppComponent {

  private organization: Organization | undefined | null;
  sideMode: ConfigSideMode = ConfigSideMode.MODE_EXPANDED;
  sideModeEnum = ConfigSideMode;
  displaySitesFilters: boolean = false;

  constructor(private store: Store,
              private siteService: SiteService,
              private keycloakService: KeycloakService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private router: Router,
              private actions: Actions,
              public title: Title,
              private translocoService: TranslocoService) {
    this.translocoService.setActiveLang(navigator.language.slice(0,2));
    this.initHandleConfig();
    this.initHandleOrganization();
    this.initHandleUser();
    this.initHandleSite();
    this.initHandlePipeline();
    this.store.dispatch(getUser());
  }

  private initHandlePipeline(): void {
    this.actions.pipe(ofType(startPerreadPipelineSuccess), takeUntilDestroyed())
      .subscribe(data => {
        this.store.select(selectUser)
          .pipe(first())
          .subscribe((user: User | undefined) => {
          if (user) {
            this.toastr.success(this.translocoService.translate('start_perread_pipeline_success', {mail: user.email}));
          }
        })
      });

    this.actions.pipe(ofType(startPerreadPipelineFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.error(this.translocoService.translate('start_perread_pipeline_failure'));
      });
  }

  private initHandleConfig(): void {
    this.store.select(selectConfigSideMode)
      .pipe(takeUntilDestroyed())
      .subscribe((sideMode: ConfigSideMode) => {
        this.sideMode = sideMode;
      });
    this.store.select(selectConfigDisplaySitesFilters)
      .pipe(takeUntilDestroyed())
      .subscribe((displaySitesFilters: boolean) => {
        this.displaySitesFilters = displaySitesFilters;
      });
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe( result => {
        if (result) {
          this.store.dispatch(loadOrganizations());
        }
      });

    this.actions.pipe(ofType(getUserSuccess), first())
      .subscribe(result => {
        const user = result['user'];
        this.translocoService.setActiveLang(user.languageCode);
      });
  }

  private initHandleOrganization(): void {
    this.store.select(selectCurrentOrganization)
      .pipe(takeUntilDestroyed())
      .subscribe( (organization: Organization | null | undefined) => {
        if (organization) {
          if (!this.organization || (this.organization && this.organization.id != organization.id)) {
            this.organization = organization;
            this.title.setTitle(this.organization.name);
            this.store.dispatch(loadSectors({organizationId: this.organization.id}));
            this.store.dispatch(loadSites({organizationId: this.organization.id}));
          }
        }
      });

    this.actions.pipe(ofType(loadOrganizationsSuccess), first())
      .subscribe(result => {
        const organizations = result['organizations']
        if (organizations.length == 0){
          this.displayUserConfigurationErrorDialog();
        }
      });

    this.actions.pipe(ofType(loadOrganizationsFailure), first())
      .subscribe(result => {
        this.displayUserConfigurationErrorDialog();
      });

    this.store.select(selectAllOrganizations)
      .pipe(takeUntilDestroyed())
      .subscribe( result => {
        if (result.length && !this.organization) {
          const organization = result[0];
          this.store.dispatch(selectOrganization({organizationId: organization.id}));
        }
      });
  }

  private initHandleSite(): void {
    this.actions.pipe(ofType(activateSiteSuccess), takeUntilDestroyed())
      .subscribe(data => {
        const site: any = data['update']['changes'];
        this.toastr.success(this.translocoService.translate('activate_site_success', {site: site['name']}));
      });
    this.actions.pipe(ofType(activateSiteFailure), takeUntilDestroyed())
      .subscribe(data => {
        this.toastr.error(this.translocoService.translate('activate_site_failure'));
      });

    this.actions.pipe(ofType(deactivateSiteSuccess), takeUntilDestroyed())
      .subscribe(data => {
        const site: any = data['update']['changes'];
        this.toastr.success(this.translocoService.translate('deactivate_site_success', {site: site['name']}));
        this.store.dispatch(selectSite({siteId: undefined}));
      });
    this.actions.pipe(ofType(deactivateSiteFailure), takeUntilDestroyed())
      .subscribe(data => {
        this.toastr.error(this.translocoService.translate('deactivate_site_failure'));
      });
  }

  private displayUserConfigurationErrorDialog(): void {
    const dialogRef = this.dialog.open(UserConfigurationErrorDialog, {
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.keycloakService.logout().catch(err => this.toastr.error(err));
    });
  }


  toggleSideMode(): void {
    const mode = (this.sideMode === this.sideModeEnum.MODE_EXPANDED) ? this.sideModeEnum.MODE_COLLAPSED : this.sideModeEnum.MODE_EXPANDED
    this.store.dispatch(setConfigSideMode({sideMode: mode}));
  }

  displayOrganizations(): void {
    const dialogRef = this.dialog.open(OrganizationDialog, {
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(organizationId => {
      if (organizationId) {
        this.store.dispatch(selectOrganization({organizationId}));
      }
    });
  }

  logout(): void {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      disableClose: false
    });
    dialogRef.componentInstance.icon = 'logout';
    dialogRef.componentInstance.title = this.translocoService.translate('logout');
    dialogRef.componentInstance.message = this.translocoService.translate('logout_message');
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.keycloakService.logout().catch(err => this.toastr.error(err));
      }
    });
  }

  onClickDeactivateSite(site: Site) {
    const deactivateSiteDialogRef = this.dialog.open(SiteDeactivationDialog);
    deactivateSiteDialogRef.componentInstance.siteName = site.name;
    deactivateSiteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.dispatch(deactivateSite({
          organizationId: site.organization,
          siteId: site.id
        }));
      }
    });
  }

  close(site: any): void {
    this.siteService.flyTo(site);
    this.store.dispatch(selectSite({siteId: undefined}));
  }

  get user$(): Observable<User | undefined> {
    return this.store.select(selectUser);
  }

  get organizationCount$(): Observable<number> {
    return this.store.select(selectOrganizationTotal);
  }

  get organization$(): Observable<Organization | undefined | null> {
    return this.store.select(selectCurrentOrganization);
  }

  get site$(): Observable<Site | undefined | null> {
    return this.store.select(selectCurrentSite);
  }

  // keep this for later
  // product_permission(user:User, product: string): boolean {
  //   const requiredRoles = [`${this.organization?.slug}_${product}`];
  //   return requiredRoles.every((role) => user.roles.includes(role));
  // }
}
