import { createAction, props } from '@ngrx/store';
import { Marker } from '../../models/marker';
import { Update } from '@ngrx/entity';
import { Comment } from '../../models/comment';
import { Elevation } from '../../models/elevation';

export const loadMarkers = createAction('[Marker/API] Load Markers ', props<{ organizationId: string, siteId: string}>());
export const loadMarkersSuccess = createAction('[Marker/API] Load Markers success', props<{ markers: Marker[] }>());
export const loadMarkersFailure = createAction('[Marker/API] Load Markers failure', props<{ error: any }>());

export const addMarker = createAction('[Marker] Add Marker ', props<{ organizationId: string, siteId: string, point: Array<number>, cameraPosition: Array<number> }>());
export const addMarkerSuccess = createAction('[Marker/API] Add Marker success', props<{ marker: Marker}>());
export const addMarkerFailure = createAction('[Marker/API] Add Marker failure', props<{ error: any }>());

export const updateMarker = createAction('[Marker/API] Update Marker ', props<{ organizationId: string, siteId: string, markerId: string, payload: any }>());
export const updateMarkerSuccess = createAction('[Marker/API] Update Marker success', props<{ update: Update<any> }>());
export const updateMarkerFailure = createAction('[Marker/API] Update Marker failure', props<{ error: any }>());

export const deleteMarker = createAction('[Marker] Delete Marker', props<{ organizationId: string, siteId: string, markerId: string }>());
export const deleteMarkerSuccess = createAction('[Marker/API] Delete Marker success', props<{ id: string }>());
export const deleteMarkerFailure = createAction('[Marker/API] Delete Marker failure', props<{ error: any }>());

export const clearAllMarkers = createAction('[Marker] Clear All Markers');
export const selectMarker= createAction('[Marker] Select Marker', props<{ id: string | undefined}>());

export const loadMarkerComments = createAction('[Marker/API] Load Marker Comments ', props<{ organizationId: string, siteId: string, markerId: string  }>());
export const loadMarkerCommentsSuccess = createAction('[Marker/API] Load Marker Comments success', props<{ comments: Array<Comment> }>());
export const loadMarkerCommentsFailure = createAction('[Marker/API] Load Marker Comments failure', props<{ error: any }>());

export const addMarkerComment = createAction('[Marker/API] Add Marker Comment ', props<{ organizationId: string, siteId: string, markerId: string, payload: any }>());
export const addMarkerCommentSuccess = createAction('[Marker/API] Add Marker Comment success', props<{ comment: Comment }>());
export const addMarkerCommentFailure = createAction('[Marker/API] Add Marker Comment failure', props<{ error: any }>());

export const setMarkerShow = createAction('[Marker] Set Marker Show property', props<{ id: string, show: boolean}>());

export const addMarkers = createAction('[Marker/API] Add Markers', props<{ organizationId: string, siteId: string, markers: any }>());
export const addMarkersSuccess = createAction('[Marker/API] Add Markers success', props<{ markers: Array<Marker> }>());
export const addMarkersFailure = createAction('[Marker/API] Add Markers failure', props<{ error: any }>());
