<mat-card *ngIf="cut"
          class="card-cut"
          [style.border]=border
          (click)="onClickEdit(cut)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{cut.name}}</mat-card-title>
  </mat-card-header>

  <div  *ngIf="(currentUser$ | async) as currentUser" class="shared-item-indicators">
    <mat-icon *ngIf="!cut.show">visibility_off</mat-icon>
    <mat-icon *ngIf="cut.isShared && currentUser.name == cut.author">person</mat-icon>
    <mat-icon *ngIf="cut.isShared">share</mat-icon>
  </div>
</mat-card>



