import {
  Component,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Site } from '../../../../models/site';
import { InspectionModule } from '../../../../enums/inspection-module';
import { ViewRepresentation } from '../../../../enums/representation';
import {
  setConfigViewRepresentation
} from '../../../../features/config/config.actions';
import { Batch } from '../../../../models/batch';
import { selectAllBatchs } from '../../../../features/batch/batch.selectors';
import {
  selectVolumeSummary
} from '../../../../features/volume/volume.selectors';
import {
  selectDetectionSummary
} from '../../../../features/detection/detection.selectors';
import {
  selectConfigViewRepresentation, selectSplittedTilesetIsLoading,
  selectTilesetIsLoading
} from '../../../../features/config/config.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  addTilesetConfig, addTilesetConfigFailure, addTilesetConfigSuccess
} from '../../../../features/tileset-config/tileset-config.actions';
import {
  loadVolumes, loadVolumesFailure,
  loadVolumesSuccess
} from '../../../../features/volume/volume.actions';
import {
  loadDetections, loadDetectionsFailure, loadDetectionsSuccess
} from '../../../../features/detection/detection.actions';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';


@Component({
  selector: 'view-representation-selector',
  templateUrl: './view-representation-selector.component.html',
  styleUrls: ['./view-representation-selector.component.scss'],
})
export class ViewRepresentationSelectorComponent {

  site: Site | null | undefined;
  batchs: Array<Batch> = [];
  volumeSummary: any;
  detectionSummary: any;
  viewRepresentation = ViewRepresentation;
  inspectionModule = InspectionModule;
  representationControl = new FormControl(ViewRepresentation.TEXTURED);
  tilesetConfigComputing: boolean = false;
  isLoadingVolumes: boolean = false;
  isLoadingDetections: boolean = false;

  constructor(private store: Store, private actions: Actions) {
    this.initHandleConfig();
    this.initHandleSite();
    this.initHandleBatch();
    this.initHandleVolumeSummary();
    this.initHandleDetectionSummary();
    this.initHandleTilesetConfig();
    this.initHandleVolumes();
    this.initHandleDetections();
  }

  private initHandleDetections(): void {
    this.actions.pipe(
      ofType(loadDetections),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingDetections = true;
      });
    this.actions.pipe(
      ofType(
        loadDetectionsSuccess, loadDetectionsFailure),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingDetections = false;
      });
  }

  private initHandleVolumes(): void {
    this.actions.pipe(
      ofType(loadVolumes),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingVolumes = true;
      });
    this.actions.pipe(
      ofType(
        loadVolumesSuccess, loadVolumesFailure),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingVolumes = false;
      });
  }

  private initHandleTilesetConfig(): void {
    this.actions.pipe(
      ofType(addTilesetConfig),
      takeUntilDestroyed())
      .subscribe(result => {
        this.tilesetConfigComputing = true;
      });
    this.actions.pipe(
      ofType(
        addTilesetConfigSuccess, addTilesetConfigFailure),
      takeUntilDestroyed())
      .subscribe(result => {
        this.tilesetConfigComputing = false;
      });
  }

  private initHandleConfig(): void {
    this.store.select(selectConfigViewRepresentation)
      .pipe(takeUntilDestroyed())
      .subscribe((viewRepresentation) => {
        if (viewRepresentation != this.representationControl.value) {
          this.representationControl.setValue(viewRepresentation);
        }
      });
  }

  private initHandleDetectionSummary(): void {
    this.store.select(selectDetectionSummary)
      .pipe(takeUntilDestroyed())
      .subscribe((summary) => {
        this.detectionSummary = summary;
      });
  }

  private initHandleVolumeSummary(): void {
    this.store.select(selectVolumeSummary)
      .pipe(takeUntilDestroyed())
      .subscribe((summary) => {
        this.volumeSummary = summary;
      });
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.site = site;
        }
      });
  }

  private initHandleBatch(): void {
    this.store.select(selectAllBatchs)
      .pipe(takeUntilDestroyed())
      .subscribe((batchs: Array<Batch> | null | undefined) => {
          this.batchs = (batchs) ? batchs : [];
      });
  }

  onChangeRepresentation(event: any, matSelect: MatSelect): void {
    this.store.dispatch(setConfigViewRepresentation({value: event.value}));
    matSelect.close();
  }

  get isTilesetLoading$(): Observable<boolean> {
    return this.store.select(selectTilesetIsLoading);
  }

  get isSplittedTilesetLoading$(): Observable<boolean> {
    return this.store.select(selectSplittedTilesetIsLoading);
  }

}
