import {
  autoserialize,
  autoserializeAs,
  deserialize, deserializeAs,
} from 'cerialize';


export class AreaVolume {
  @autoserializeAs("projected_polygon") projectedPolygon!: Array<Array<number>>;
  @autoserializeAs("projected_area") projectedArea!: number;
  @autoserializeAs("terrain_area") terrainArea!: number;
  @autoserializeAs("up_full_volume") upFullVolume!: number;
  @autoserializeAs("up_error") upError!: number;
  @autoserializeAs("down_full_volume") downFullVolume!: number;
  @autoserializeAs("down_empty_volume") downEmptyVolume!: number;
  @autoserializeAs("down_error") downError!: number;
}

export class StraightMeasure {
  @autoserializeAs("converted_point1") convertedPoint1!: Array<number>;
  @autoserializeAs("converted_point2") convertedPoint2!: Array<number>;
  @autoserializeAs("direct_distance") directDistance!: number;
  @autoserializeAs("top_terrain_distance") topTerrainDistance!: number;
  @autoserializeAs("face_terrain_distance") faceTerrainDistance!: number;
}

export class Measure {
  kind: string = 'measure';
  checked: boolean = true;
  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize author!: string;
  @autoserializeAs(AreaVolume, "area_volume") areaVolume!: AreaVolume;
  @autoserializeAs(StraightMeasure, "straight_measure") straightMeasure!: StraightMeasure;
  @autoserialize point1!: Array<number>;
  @autoserialize point2!: Array<number>;
  @deserialize polygon!: Array<Array<number>>;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserialize length!: number;
  @deserializeAs('is_shared') isShared!: boolean;
  show: boolean;

  constructor() {
    this.show = true;
  }

}
