import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as TilesetConfigActions from './tileset-config.actions';
import { TilesetConfig } from '../../models/tileset-config';


export interface State extends EntityState<TilesetConfig> {
  selectedTilesetConfigId: string | undefined;
}

export const adapter: EntityAdapter<TilesetConfig> = createEntityAdapter<TilesetConfig>();

export const initialState: State = adapter.getInitialState({
  selectedTilesetConfigId: undefined,
});

const tilesetConfigReducer = createReducer(
  initialState,
  on(TilesetConfigActions.setTilesetConfigId, (state, { tilesetConfigId }) => {
    return { ...state, selectedTilesetConfigId: tilesetConfigId };
  }),
  on(TilesetConfigActions.loadTilesetConfigs, (state) => {
    return state;
  }),
  on(TilesetConfigActions.loadTilesetConfigsSuccess, (state, { tilesetConfigs }) => {
    return adapter.setAll(tilesetConfigs, state);
  }),
  on(TilesetConfigActions.loadTilesetConfigsFailure, (state, { error }) => {
    return state;
  }),
  on(TilesetConfigActions.addTilesetConfig, (state) => {
    return state;
  }),
  on(TilesetConfigActions.addTilesetConfigSuccess, (state, { tilesetConfig }) => {
    return adapter.setOne(tilesetConfig, state);
  }),
  on(TilesetConfigActions.addTilesetConfigFailure, (state, { error }) => {
    return state;
  }),
  on(TilesetConfigActions.clearAllTilesetConfigs, (state, {}) => {
    return adapter.removeAll(state);
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return tilesetConfigReducer(state, action);
}

export const getSelectedTilesetConfigId = (state: State) => state.selectedTilesetConfigId;
