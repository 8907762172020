import { createAction, props } from '@ngrx/store';
import { Measure } from '../../models/measure';
import { Update } from '@ngrx/entity';
import { Comment } from '../../models/comment';


export const loadMeasures = createAction('[Measure/API] Load Measures ', props<{ organizationId: string, siteId: string, batchId: string}>());
export const loadMeasuresSuccess = createAction('[Measure/API] Load Measures success', props<{ measures: Measure[] }>());
export const loadMeasuresFailure = createAction('[Measure/API] Load Measures failure', props<{ error: any }>());

export const addMeasureDistance = createAction('[Measure] Add Measure Distance', props<{ organizationId: string, siteId: string, batchId: string, point1: Array<number>, point2: Array<number>, cameraPosition: Array<number> }>());
export const addMeasureArea = createAction('[Measure] Add Measure Area', props<{ organizationId: string, siteId: string, batchId: string, polygon: Array<Array<number>>, cameraPosition: Array<number> }>());
export const addMeasureSuccess = createAction('[Measure/API] Add Measure success', props<{ measure: Measure}>());
export const addMeasureFailure = createAction('[Measure/API] Add Measure failure', props<{ error: any }>());

export const updateMeasure = createAction('[Measure/API] Update Measure ', props<{ organizationId: string, siteId: string, batchId: string, measureId: string, payload: any }>());
export const updateMeasureSuccess = createAction('[Measure/API] Update Measure success', props<{ update: Update<any> }>());
export const updateMeasureFailure = createAction('[Measure/API] Update Measure failure', props<{ error: any }>());

export const deleteMeasure = createAction('[Measure] Delete Measure', props<{ organizationId: string, siteId: string, batchId: string, measureId: string }>());
export const deleteMeasureSuccess = createAction('[Measure/API] Delete Measure success', props<{ id: string }>());
export const deleteMeasureFailure = createAction('[Measure/API] Delete Measure failure', props<{ error: any }>());

export const clearAllMeasures = createAction('[Measure] Clear All Measures');
export const selectMeasure = createAction('[Measure] Select Measure', props<{ id: string | undefined}>());


export const loadMeasureComments = createAction('[Measure/API] Load Measure Comments ', props<{ organizationId: string, siteId: string, batchId: string, measureId: string  }>());
export const loadMeasureCommentsSuccess = createAction('[Measure/API] Load Measure Comments success', props<{ comments: Array<Comment> }>());
export const loadMeasureCommentsFailure = createAction('[Measure/API] Load Measure Comments failure', props<{ error: any }>());

export const addMeasureComment = createAction('[Measure/API] Add Measure Comment ', props<{ organizationId: string, siteId: string, batchId: string, measureId: string, payload: any }>());
export const addMeasureCommentSuccess = createAction('[Measure/API] Add Measure Comment success', props<{ comment: Comment }>());
export const addMeasureCommentFailure = createAction('[Measure/API] Add Measure Comment failure', props<{ error: any }>());

export const setMeasureShow = createAction('[Measure] Set Measure Show property', props<{ id: string, show: boolean}>());
