import { Component, Input } from '@angular/core';
import { VolumeSummary } from '../../../../models/site';


@Component({
  selector: 'volume-summary',
  templateUrl: './volume-summary.component.html'
})
export class VolumeSummaryComponent {

  @Input({required: true}) volumeSummary: VolumeSummary | undefined;

  constructor() {

  }
}
