import { createAction, props } from '@ngrx/store';
import {
  InspectionReport,
} from '../../models/inspection-report';

export const reportWithData = createAction('[Report/API] Report with data', props<{ data: any }>());
export const reportRequireAllData = createAction('[Report/API] Report require all data');

export const generateReport = createAction('[Report/API] Generate Report ', props<{ organizationId: string, siteId: string, batchId: string, payload: InspectionReport}>());
export const generateReportSuccess = createAction('[Report/API] Generate Report success', props<{ email: string }>());
export const generateReportFailure = createAction('[Report/API] Generate Report failure', props<{ error: any }>());


