<div *ngIf="site" class="container">
  <div class="left">
    <button *ngIf="site.isActivableByUser"
            mat-icon-button
            matTooltip="{{ 'deactivate' | transloco }}"
            (click)="onClickDeactivateSite(site)">
      power_settings_new
    </button>
  </div>
  <div class="right">
    <button mat-icon-button
            matTooltip="{{'upload' | transloco}}"
            [matMenuTriggerFor]="importMenu">
      upload
    </button>
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
    <mat-menu #importMenu="matMenu">
      <button mat-menu-item (click)="onClickUploadCSVCut(site)">{{'upload_cvs_cut' | transloco}}</button>
      <button mat-menu-item (click)="onClickUploadCSVElevation(site)">{{'upload_cvs_elevation' | transloco}}</button>
      <button mat-menu-item (click)="onClickUploadCSVMarker(site)">{{'upload_cvs_marker' | transloco}}</button>
    </mat-menu>
    <button mat-icon-button
            matTooltip="{{'download' | transloco}}"
            [matMenuTriggerFor]="exportMenu">
        download
    </button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="onClickDownloadPointCloudDense(site)">{{'download_point_cloud_dense' | transloco}}</button>
      <button mat-menu-item (click)="onClickDownloadPointCloudSparse(site)">{{'download_point_cloud_sparse' | transloco}}</button>
    </mat-menu>
<!--    <button mat-icon-button-->
<!--            matTooltip="{{'generate_report' | transloco}}" (click)="selectReportMode()">-->
<!--      assignment-->
<!--    </button>-->
  </div>
</div>
