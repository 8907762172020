
export class FileTools {

  private static csvFileToArray(fileContent: any, nbCol: number): Array<Array<string>> | undefined {
    const data: any[] = [];
    const rows = fileContent.split('\n');
    for (let i = 0; i < rows.length; i++) {
      const columns = rows[i].split(';');
      if (columns.length == nbCol) {
        const emptyCol = columns.filter((col:string) => col.length == 0);
        if (emptyCol.length) {
          return undefined;
        }
        data.push(columns);
      }
      else {
        // Last row ignored if not match column count cause it can exist with \n row separator.
        // But this must be an error on other rows
        if (i != (rows.length - 1)) {
          return undefined;
        }
      }
    }
    return (data.length) ? data : undefined;
  }

  static cvsFileCutValidator(csvFile: any): Array<Array<string>> | undefined {
    return FileTools.csvFileToArray(csvFile, 6);
  }

  static cvsFileElevationValidator(csvFile: any): Array<Array<string>> | undefined {
    return FileTools.csvFileToArray(csvFile, 3);
  }

  static cvsFileMarkerValidator(csvFile: any): Array<Array<string>> | undefined {
    return FileTools.csvFileToArray(csvFile, 3);
  }
}
