<div class="container" *ngIf="site">
  <mat-form-field>
    <mat-label>{{'representation' | transloco}}</mat-label>
    <mat-select #matSelect
                [formControl]="representationControl"
                (selectionChange)="onChangeRepresentation($event, matSelect)">
      <mat-option [value]="viewRepresentation.TEXTURED">
        <div class="option">
          <div>{{'representation_textured' | transloco}}</div>
          <div *ngIf="(isTilesetLoading$ | async) && representationControl.value == viewRepresentation.TEXTURED"><mat-spinner strokeWidth="5"></mat-spinner></div>
        </div>
      </mat-option>
      <mat-option [value]="viewRepresentation.POINT_CLOUD">
        <div class="option">
          <div>{{'representation_point_cloud' | transloco}}</div>
          <div *ngIf="(isTilesetLoading$ | async) && representationControl.value == viewRepresentation.POINT_CLOUD"><mat-spinner strokeWidth="5"></mat-spinner></div>
        </div>
      </mat-option>
      <mat-option *ngIf="batchs.length > 1"
                  [value]="viewRepresentation.SPLITTED_TEXTURED">
        <div class="option">
          <div>{{'representation_splitted_textured' | transloco}}</div>
          <div *ngIf="(isSplittedTilesetLoading$ | async) && representationControl.value == viewRepresentation.SPLITTED_TEXTURED"><mat-spinner strokeWidth="5"></mat-spinner></div>
        </div>
      </mat-option>
      <mat-option *ngIf="batchs.length > 1"
                  [value]="viewRepresentation.SPLITTED_POINT_CLOUD">
        <div class="option">
          <div>{{'representation_splitted_pointcloud' | transloco}}</div>
          <div *ngIf="(isSplittedTilesetLoading$ | async) && representationControl.value == viewRepresentation.SPLITTED_POINT_CLOUD"><mat-spinner strokeWidth="5"></mat-spinner></div>
        </div>
      </mat-option>
      <mat-option *ngIf="(site | module: [inspectionModule.ARCAD])"
                  [value]="viewRepresentation.DETECTIONS">
        <div class="option">
          <div>{{'representation_detections' | transloco}}</div>
          <div *ngIf="(isTilesetLoading$ | async) && representationControl.value == viewRepresentation.DETECTIONS"><mat-spinner strokeWidth="5"></mat-spinner></div>
        </div>
      </mat-option>
      <mat-option *ngIf="(site | module: [inspectionModule.PERREAD]) && batchs.length > 1"
                  [value]="viewRepresentation.VOLUMES">
        <div class="option">
          <div>{{'representation_volumes' | transloco}}</div>
          <div *ngIf="(isTilesetLoading$ | async) && representationControl.value == viewRepresentation.VOLUMES"><mat-spinner strokeWidth="5"></mat-spinner></div>
        </div>
      </mat-option>
      <mat-option *ngIf="batchs.length > 1"
                  [value]="viewRepresentation.DIFFERENTIAL">
        <div class="option">
          <div>{{'representation_differential' | transloco}}</div>
          <div *ngIf="(isTilesetLoading$ | async) && representationControl.value == viewRepresentation.DIFFERENTIAL"><mat-spinner strokeWidth="5"></mat-spinner></div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-spinner *ngIf="representationControl.value == viewRepresentation.TEXTURED && (isTilesetLoading$ | async)" class="view-selected-spinner" strokeWidth="5"></mat-spinner>
  <mat-spinner *ngIf="representationControl.value == viewRepresentation.POINT_CLOUD && (isTilesetLoading$ | async)" class="view-selected-spinner" strokeWidth="5"></mat-spinner>
  <mat-spinner *ngIf="representationControl.value == viewRepresentation.SPLITTED_TEXTURED && (isSplittedTilesetLoading$ | async)" class="view-selected-spinner" strokeWidth="5"></mat-spinner>
  <mat-spinner *ngIf="representationControl.value == viewRepresentation.SPLITTED_POINT_CLOUD && (isSplittedTilesetLoading$ | async)" class="view-selected-spinner" strokeWidth="5"></mat-spinner>
  <mat-spinner *ngIf="representationControl.value == viewRepresentation.DETECTIONS && isLoadingDetections" class="view-selected-spinner" strokeWidth="5"></mat-spinner>
  <mat-spinner *ngIf="representationControl.value == viewRepresentation.VOLUMES && isLoadingVolumes" class="view-selected-spinner" strokeWidth="5"></mat-spinner>
  <mat-spinner *ngIf="representationControl.value == viewRepresentation.DIFFERENTIAL && ((isTilesetLoading$ | async) || tilesetConfigComputing)" class="view-selected-spinner" strokeWidth="5"></mat-spinner>

<!--  <table>-->
<!--    <tr><td>viewRepresentation</td><td>{{representationControl.value}}</td></tr>-->
<!--    <tr><td>tilesetIsLoading</td><td>{{(isTilesetLoading$ | async)}}</td></tr>-->
<!--    <tr><td>isLoadingDetections</td><td>{{isLoadingDetections}}</td></tr>-->
<!--    <tr><td>isLoadingVolumes</td><td>{{tilesetConfigComputing}}</td></tr>-->
<!--  </table>-->

</div>
