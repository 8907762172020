import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CutActions from './cut.actions';
import { Cut } from '../../models/cut';


export interface State extends EntityState<Cut> {
  selectedId: string | undefined;
}

export const adapter: EntityAdapter<Cut> = createEntityAdapter<Cut>();

export const initialState: State = adapter.getInitialState({selectedId: undefined});

const cutReducer = createReducer(
  initialState,
  on(CutActions.loadCuts, (state) => {
    return state;
  }),
  on(CutActions.loadCutsSuccess, (state, { cuts }) => {
    return adapter.setAll(cuts, state);
  }),
  on(CutActions.loadCutsFailure, (state, { error }) => {
    return state;
  }),
  on(CutActions.addCut, (state) => {
    return state;
  }),
  on(CutActions.addCutSuccess, (state, { cut }) => {
    return adapter.setOne(cut, state);
  }),
  on(CutActions.addCutFailure, (state, { error }) => {
    return state;
  }),
  on(CutActions.updateCut, (state) => {
    return state;
  }),
  on(CutActions.updateCutSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(CutActions.updateCutFailure, (state) => {
    return state;
  }),
  on(CutActions.deleteCut, (state) => {
    return state;
  }),
  on(CutActions.deleteCutSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(CutActions.deleteCutFailure, (state, { error }) => {
    return state;
  }),
  on(CutActions.clearAllCuts, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(CutActions.selectCut, (state, {id}) => {
    return { ...state, selectedId: id };
  }),
  on(CutActions.setCutShow, (state, {id, show}) => {
    return adapter.updateOne({id: id, changes: {show : show}}, state);
  }),
  on(CutActions.addCuts, (state) => {
    return state;
  }),
  on(CutActions.addCutsSuccess, (state, { cuts }) => {
    return adapter.setAll(cuts, state);
  }),
  on(CutActions.addCutsFailure, (state, { error }) => {
    return state;
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return cutReducer(state, action);
}

export const getSelectedCutId = (state: State) => state.selectedId;
