import { Injectable } from '@angular/core';
import { DetectionDensity, VolumeDensity } from '../models/site';
import { CesiumTools } from '../tools/cesium.tools';
import { Detection } from '../models/detection';
import { Volume } from '../models/volume';

@Injectable({
  providedIn: 'root'
})
export class CesiumPrimitiveService {


  createDensityPrimitive(collection: any, density: DetectionDensity | VolumeDensity, overwritedColor: string | undefined = undefined): void {
    const color = (overwritedColor) ? Cesium.Color.fromCssColorString(overwritedColor) : Cesium.Color.fromCssColorString(density.colorHex);
    const center = Cesium.Cartesian3.fromArray(density.point);
    const rm = density.rotationMatrix;
    const rotationMatrix = Cesium.Matrix3.fromArray([
      rm[0][0],
      rm[1][0],
      rm[2][0],
      rm[0][1],
      rm[1][1],
      rm[2][1],
      rm[0][2],
      rm[1][2],
      rm[2][2],
    ]);
    const modelMatrix = Cesium.Matrix4.fromRotationTranslation(rotationMatrix, center);
    const dimensions = Cesium.Cartesian3.fromArray(density.dimensions);

    const primitive = new Cesium.ClassificationPrimitive({
      geometryInstances: new Cesium.GeometryInstance({
        geometry: Cesium.BoxGeometry.fromDimensions({
          vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
          dimensions,
        }),
        modelMatrix,
        attributes: {
          color: Cesium.ColorGeometryInstanceAttribute.fromColor(
            color.withAlpha(0.5)
          ),
          show: new Cesium.ShowGeometryInstanceAttribute(true),
        },
        id: 'density'
      }),
      classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
    });
    collection.add(primitive);
  }

  createDetectionPrimitive(collection: any, detection: Detection, isSelected: boolean = false): void{
    const center = Cesium.Cartesian3.fromArray(detection.box.center);
    const rm = detection.box.rotationMatrix;
    const rotationMatrix = Cesium.Matrix3.fromArray([
      rm[0][0],
      rm[1][0],
      rm[2][0],
      rm[0][1],
      rm[1][1],
      rm[2][1],
      rm[0][2],
      rm[1][2],
      rm[2][2],
    ]);
    const modelMatrix = Cesium.Matrix4.fromRotationTranslation(rotationMatrix, center);
    const dimensions = Cesium.Cartesian3.fromArray(detection.box.dimensions);
    const geometry = Cesium.BoxGeometry.fromDimensions({
      vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
      dimensions
    });

    let geometryInstances = [];
    const color = Cesium.ColorGeometryInstanceAttribute.fromColor(
      Cesium.Color.fromCssColorString(detection.textColor).withAlpha( 0.7)
    );
    if(isSelected) {
      geometryInstances = CesiumTools.faceBoxGeometries(modelMatrix, dimensions, 0.02, color);
    }
    else {
      geometryInstances.push(new Cesium.GeometryInstance({
        geometry,
        modelMatrix,
        attributes: { color }
      }));
    }

    const primitive = new Cesium.ClassificationPrimitive({
      show: detection.show,
      classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
      geometryInstances,
    });
    primitive['entity'] = detection;
    primitive['isSelected'] = isSelected;

    collection.add(primitive);
  }


  createVolumePrimitive(collection: any, volume: Volume): void{
    const color = Cesium.Color.fromCssColorString(volume.hexColor);
    const center = Cesium.Cartesian3.fromArray(volume.center);

    const modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(center);

    const primitive = new Cesium.ClassificationPrimitive({
      classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
      appearance : new Cesium.PerInstanceColorAppearance({
        translucent : false,
        closed : true
      }),
      geometryInstances: new Cesium.GeometryInstance({
        geometry: Cesium.BoxGeometry.fromDimensions({
          vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
          dimensions: new Cesium.Cartesian3(10, 10, 10),
        }),
        modelMatrix,
        attributes: {
          color: Cesium.ColorGeometryInstanceAttribute.fromColor(
            color.withAlpha(0.5)
          ),
          show: new Cesium.ShowGeometryInstanceAttribute(true),
        },
        id: volume,
      })
    });

    collection.add(primitive);
  }

}
