import { autoserialize, autoserializeAs, serialize } from 'cerialize';
import { Emergency } from './emergency';

export class Volume {
  kind: string = 'volume';
  checked: boolean = true;
  @serialize name!: string;
  @autoserialize id!: string;
  @autoserialize polygon!: Array<Array<number>>;
  @autoserialize index!: number;
  @autoserializeAs(Emergency, 'emergency')  emergency!: Emergency;
  @autoserializeAs('horizontal_projection') horizontalProjection!: boolean;
  @autoserialize volume!: number;
  @autoserializeAs('negative_volume') negativeValue!: number;
  @autoserializeAs('positive_volume') positiveValue!: number;
  @autoserialize center!: Array<number>;
  @autoserializeAs('camera_direction') cameraDirection!: Array<number>;
  @autoserialize length!: number;
  hexColor!: string;
  border!: string;
  backgroundColor!: string;
  textColor!: string;
}
