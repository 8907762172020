import { createAction, props } from '@ngrx/store';
import { TilesetConfig } from '../../models/tileset-config';
import { Site } from '../../models/site';

export const loadTilesetConfigs = createAction('[TilesetConfig/API] Load TilesetConfigs ', props<{ site: Site }>());
export const loadTilesetConfigsSuccess = createAction('[TilesetConfig/API] Load TilesetConfigs success', props<{ tilesetConfigs: TilesetConfig[] }>());
export const loadTilesetConfigsFailure = createAction('[TilesetConfig/API] Load TilesetConfigs failure', props<{ error: any }>());

export const addTilesetConfig = createAction('[TilesetConfig] Add TilesetConfig ', props<{ organizationId: string, siteId: string, batch_compared: string, batch_reference: string }>());
export const addTilesetConfigSuccess = createAction('[TilesetConfig/API] Add TilesetConfig success', props<{ tilesetConfig: TilesetConfig}>());
export const addTilesetConfigFailure = createAction('[TilesetConfig/API] Add TilesetConfig failure', props<{ error: any }>());

export const setTilesetConfigId = createAction('[TilesetConfig] Select TilesetConfig', props<{ tilesetConfigId: string | undefined}>());

export const clearAllTilesetConfigs = createAction('[TilesetConfigs] Clear All TilesetConfigs');
