import { createAction, props } from '@ngrx/store';
import { Detection } from '../../models/detection';
import { EmergencyTemplate } from '../../models/emergency-template';
import { Update } from '@ngrx/entity';
import { DetectionTemplate } from '../../models/detection-template';
import { Comment } from '../../models/comment';

export const loadDetections = createAction('[Detection/API] Load Detections ', props<{  organizationId: string, siteId: string, batchId: string }>());
export const loadDetectionsSuccess = createAction('[Detection/API] Load Detections success', props<{ detections: Detection[] }>());
export const loadDetectionsFailure = createAction('[Detection/API] Load Detections failure', props<{ error: any }>());

export const clearAllDetections = createAction('[Detection] Clear All Detections');
export const selectDetection = createAction('[Detection] Select Detection', props<{ id: string | undefined}>());

export const loadDetectionEmergencyTemplate = createAction('[Detection/API] Load Detection Emergency Template ', props<{  organizationId: string, siteId: string }>());
export const loadDetectionEmergencyTemplateSuccess = createAction('[Detection/API] Load Detection Emergency Template success', props<{ template: EmergencyTemplate }>());
export const loadDetectionEmergencyTemplateFailure = createAction('[Detection/API] Load Detection Emergency Template failure', props<{ error: any }>());
export const clearDetectionEmergencyTemplate = createAction('[Detection] Clear Detection Emergency Template');

export const addDetectionEmergency = createAction('[Detection/API] Add Detection Emergency ', props<{ organizationId: string, siteId: string, batchId: string, detectionId: string, payload: any }>());
export const addDetectionEmergencySuccess = createAction('[Detection/API] Add Detection Emergency success', props<{ update: Update<any> }>());
export const addDetectionEmergencyFailure = createAction('[Detection/API] Add Detection Emergency failure', props<{ error: any }>());

export const updateDetection = createAction('[Detection/API] Update Detection ', props<{ organizationId: string, siteId: string, batchId: string, detectionId: string, payload: any }>());
export const updateDetectionSuccess = createAction('[Detection/API] Update Detection success', props<{ update: Update<any> }>());
export const updateDetectionFailure = createAction('[Detection/API] Update Detection failure', props<{ error: any }>());

export const deleteDetection = createAction('[Detection] Delete Detection', props<{ organizationId: string, siteId: string, batchId: string, detectionId: string, payload: any }>());
export const deleteDetectionSuccess = createAction('[Detection/API] Delete Detection success', props<{ id: string }>());
export const deleteDetectionFailure = createAction('[Detection/API] Delete Detection failure', props<{ error: any }>());

export const addDetection = createAction('[Detection/API] Add Detection', props<{ organizationId: string, siteId: string, batchId: string, payload: any }>());
export const addDetectionSuccess = createAction('[Detection/API] Add Detection success', props<{ detection: Detection }>());
export const addDetectionFailure = createAction('[Detection/API] Add Detection failure', props<{ error: any }>());

export const loadDetectionTemplate = createAction('[Detection/API] Load Detection Template ', props<{  organizationId: string, siteId: string }>());
export const loadDetectionTemplateSuccess = createAction('[Detection/API] Load Detection Template success', props<{ template: DetectionTemplate }>());
export const loadDetectionTemplateFailure = createAction('[Detection/API] Load Detection Template failure', props<{ error: any }>());
export const clearDetectionTemplate = createAction('[Detection] Clear Detection Template');


export const loadDetectionComments = createAction('[Detection/API] Load Detection Comments ', props<{  detectionId: string  }>());
export const loadDetectionCommentsSuccess = createAction('[Detection/API] Load Detection Comments success', props<{ comments: Array<Comment> }>());
export const loadDetectionCommentsFailure = createAction('[Detection/API] Load Detection Comments failure', props<{ error: any }>());

export const addDetectionComment = createAction('[Detection/API] Add Detection Comment ', props<{  detectionId: string, payload: any }>());
export const addDetectionCommentSuccess = createAction('[Detection/API] Add Detection Comment success', props<{ comment: Comment }>());
export const addDetectionCommentFailure = createAction('[Detection/API] Add Detection Comment failure', props<{ error: any }>());


export const loadDetectionSummary = createAction('[Detection/API] Load Detection Summary ', props<{ organizationId: string, siteId: string, batchId: string }>());
export const loadDetectionSummarySuccess = createAction('[Detection/API] Load Detection Summary success', props<{ summary: any }>());
export const loadDetectionSummaryFailure = createAction('[Detection/API] Load Detection Summary failure', props<{ error: any }>());
export const clearDetectionSummary = createAction('[Detection] Clear Detection Summary');


export const setDetectionShow = createAction('[Detection] Set Detection Show property', props<{ id: string, show: boolean}>());
