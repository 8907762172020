<mat-card class="container" *ngIf="elevation && (currentUser$ | async) as currentUser"
          [@displayMode]="displayModeExtended ? 'expanded' : 'collapsed'"
          (@displayMode.done)="onAnimationDone()">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{elevation.name}}</mat-card-title>
      <mat-card-subtitle>{{elevation.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div [ngClass]="{
        'content-extended': displayModeExtended,
        'content-collapsed' : !displayModeExtended}">

      <elevation-chart #graph
                       class="chart">
      </elevation-chart>

      <div>
        <div *ngIf="currentUser.name != elevation.author" class="author">{{'created_by_f' | transloco }} {{elevation.author}}</div>
        <elevation-history-channel [elevationId]="elevation.id"></elevation-history-channel>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="left-actions">
      <button *ngIf="currentUser.name == elevation.author"
              mat-icon-button
              matTooltip="{{'delete' | transloco}}"
              (click)="onClickDelete(elevation)">delete
      </button>
      <div *ngIf="currentUser.name == elevation.author" class="vertical-separator"></div>
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_maker' | transloco}}"
              class="material-symbols-outlined"
              (click)="onClickFlyTo(elevation)">location_on
      </button>
      <button mat-icon-button
              matTooltip="{{'toggle_show' | transloco}}"
              (click)="onClickToggleShow(elevation)">
        <mat-icon *ngIf="elevation.show">visibility</mat-icon>
        <mat-icon *ngIf="!elevation.show">visibility_off</mat-icon>
      </button>
      <button *ngIf="currentUser.name == elevation.author"
              mat-icon-button
              matTooltip="{{'rename' | transloco}}"
              (click)="onClickRename(elevation)"><mat-icon>edit</mat-icon></button>

      <button *ngIf="!elevation.isShared && currentUser.name == elevation.author"
              mat-icon-button
              matTooltip="{{'share' | transloco}}"
              (click)="onClickToggleShare(elevation)">
        <mat-icon>share</mat-icon>
      </button>
      <button *ngIf="elevation.isShared && currentUser.name == elevation.author"
              mat-icon-button
              matTooltip="{{'share_off' | transloco}}"
              (click)="onClickToggleShare(elevation)">
        <mat-icon>share_off</mat-icon>
      </button>
    </div>
    <span class="spacer"></span>
    <button *ngIf="!displayModeExtended"
            mat-icon-button
            matTooltip="{{'extend' | transloco}}"
            (click)="onClickToggleExtend()">
      <mat-icon>open_in_full</mat-icon>
    </button>
    <button *ngIf="displayModeExtended"
            mat-icon-button
            matTooltip="{{'collapse' | transloco}}"
            (click)="onClickToggleExtend()">
      <mat-icon>close_fullscreen</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
