<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>assignment</mat-icon>&nbsp;{{'generate_report' | transloco}}
    </mat-card-title>
    <mat-card-subtitle>{{'report_select_mode' | transloco}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <mat-form-field>
      <mat-label>{{'report_selected_mode' | transloco}}</mat-label>
      <mat-select [formControl]="reportModeControl">
        <mat-option *ngFor="let mode of availableMode;" [value]="mode.value">
          <div class="option">
            <div>{{mode.name | transloco}}</div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close()">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(reportModeControl.value)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
