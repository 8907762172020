<mat-card *ngIf="marker"
          class="card-marker"
          [style.border]=border
          (click)="onClickEdit(marker)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{marker.name}}</mat-card-title>
  </mat-card-header>

  <div  *ngIf="(currentUser$ | async) as currentUser" class="shared-item-indicators">
    <mat-icon *ngIf="!marker.show">visibility_off</mat-icon>
    <mat-icon *ngIf="marker.isShared && currentUser.name == marker.author">person</mat-icon>
    <mat-icon *ngIf="marker.isShared">share</mat-icon>
  </div>
</mat-card>



