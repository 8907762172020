import { Component, Input } from '@angular/core';
import { DetectionSummary } from '../../../../models/site';


@Component({
  selector: 'detection-summary',
  templateUrl: './detection-summary.component.html'
})
export class DetectionSummaryComponent {

  @Input({required: true}) detectionSummary: DetectionSummary | undefined;

  constructor() {

  }
}
