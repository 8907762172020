<div class="container" *ngIf="comparedBatchs.length > 0">
  <mat-form-field >
    <mat-label>{{'compared_to_the_batch' | transloco}}</mat-label>
    <mat-select [formControl]="batchControl" (selectionChange)="onChangeBatch($event)">
      @for (batch of comparedBatchs; track batch) {
        <mat-option [value]="batch.id">{{batch.dateAcquired | date:dateFormat}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>



