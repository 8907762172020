import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter, Input, Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';
import { ActiveElement, Chart } from 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js/dist/types';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { selectCurrentCut } from '../../../features/cut/cut.selectors';
import { Cut, CutBatchData } from '../../../models/cut';
import { Color } from '../../../enums/color';
import { getRelativePosition } from 'chart.js/helpers';
import { DatePipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { selectUser } from '../../../features/user/user.selectors';
import {
  selectCurrentReferenceBatchId
} from '../../../features/batch/batch.selectors';



@Component({
  selector: 'cut-chart',
  template: '<canvas #canvas (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)"></canvas>',
  styles: ['canvas { width: 100%; height: 100%}']
})
export class CutChartComponent implements AfterViewInit {

  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement> | undefined;
  private cut: Cut | null | undefined;
  @Input() canEditSlope: boolean = false;
  @Output() slopeDragging: EventEmitter<Array<Array<number>> | undefined> = new EventEmitter();
  @Output() slopeHasChanged: EventEmitter<Array<Array<number>> | undefined> = new EventEmitter();
  chart: any;
  private config: any;
  private isDragging: boolean = false;
  private slopeElement: ActiveElement | undefined;
  private slope: Array<Array<number>> | undefined;

  constructor(private store: Store, private translocoService: TranslocoService) {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectCurrentCut),
      this.store.select(selectCurrentReferenceBatchId),
      this.store.select(selectUser)
    ]).pipe(takeUntilDestroyed())
      .subscribe(contents => {
        const site:any = contents[0];
        this.cut = contents[1];
        const currentBatchId = contents[2];
        const user = contents[3];

        if (site && this.cut && user) {
          this.slope = Object.assign([], this.cut.slope);
          const dateFormat = user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
          const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
          let count:number = 0;
          let datasets = this.cut.data
            .sort((a, b) => (a.batch == site.lastBatch ? -1 : 1))
            .map((profile:CutBatchData) => {
              let color;
              if (profile.batch == site.lastBatch) {
                color = Color.ANALOGOUS_1
              }
              else {
                const h:number = 225-count;
                color = 'hsl('+ h.toString() +', 100%, 33%)';
                count += 22.5;
              }

              const line:any = {
                hidden: (profile.batch != currentBatchId && profile.batch != site.lastBatch),
                label: datePipe.transform(new Date(profile.dateAcquired), dateFormat),
                data: profile.dataset,
                pointStyle: false,
                fill: false,
                borderColor: color,
                backgroundColor: color,
              };
              return line;
            });

          const color = Color.TRIADIC_2;
          let pointStyle = undefined;
          let pointRadius = 0;
          if (user.name == this.cut.author) {
            pointStyle = "circle"
            pointRadius = 5;
          }

          datasets.unshift({
            label: this.translocoService.translate("slope"),
            data: Object.assign([], this.slope),
            fill: false,
            pointStyle: pointStyle,
            pointRadius: pointRadius,
            pointHitRadius: pointRadius * 2,
            pointHoverRadius: pointRadius * 2,
            pointFill: true,
            borderColor: color,
            backgroundColor: color,
            pointBackgroundColor: color,
          });

          const data: ChartData = {
            datasets
          }

          const options: ChartOptions = {
            onHover: (event: any, elements: ActiveElement[], chart: any) => {
              const filteredElements = elements?.filter((e) => e.datasetIndex == 0);
              this.slopeElement = (filteredElements?.length) ? filteredElements[0] : undefined;
              if (this.isDragging) {
                document.body.style.cursor = 'grabbing';
              }
              else {
                document.body.style.cursor = (this.slopeElement) ? 'grab' : 'default';
              }
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            animation: {
              duration: 0
            },
            scales: {
              x:{
                type: 'linear',
              },
              y:{
                type: 'linear',
              },
            }
          }
          this.config = {
            type:'line',
            data,
            options,
          }
          this.draw();
        }
      });
  }

  private draw(): void {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
      if (ctx) {
        if (this.chart) this.chart.destroy();
        this.chart = new Chart(ctx, this.config);
      }
    }
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  resize(): void {
    this.draw();
  }

  canvasToImage(filename: string): void {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      let canvasUrl = canvas.toDataURL("image/png");
      const createEl = document.createElement('a');
      createEl.href = canvasUrl;
      createEl.download = filename.replace(/ /g,"_");
      createEl.click();
      createEl.remove();
    }
  }

  onMouseDown(event: any): void {
    if (this.slopeElement) {
      this.isDragging = true;
    }
  }

  onMouseUp(event: any): void {
    this.isDragging = false;
    document.body.style.cursor = 'default';
    if (this.slopeElement) {
      this.slopeHasChanged.next(this.slope);
    }
  }

  onMouseMove(event: any): void {
    if (this.isDragging && this.slopeElement) {
      const canvasPosition = getRelativePosition(event, this.chart);

      let valX = this.chart.scales.x.getValueForPixel(canvasPosition.x);
      let posX = canvasPosition.x;

      let valY = this.chart.scales.y.getValueForPixel(canvasPosition.y);
      let posY = canvasPosition.y;

      this.slopeElement.element.x = posX;
      this.slopeElement.element.y = posY;

      if (this.slope) {
        this.slope[this.slopeElement.index] = [valX, valY];
        this.slopeDragging.next(this.slope);
      }
    }
  }

  setChartDatasetVisible(datasetIndex: number, visible:boolean): void {
    const dataset = this.chart.data.datasets[datasetIndex];
    dataset.hidden = !visible;
    this.draw();
  }

}
