<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>assignment</mat-icon>&nbsp;{{'generate_report' | transloco}}
    </mat-card-title>
<!--    <mat-card-subtitle>{{'report_select_formats' | transloco}}</mat-card-subtitle>-->
  </mat-card-header>
  <mat-card-content>
    <mat-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>Data</ng-template>
        <mat-accordion>
          <mat-expansion-panel *ngIf="cuts.length" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ 'cuts' | transloco }} {{selectedCuts.length}}/{{cuts.length}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="items">
                <mat-checkbox *ngFor="let item of cuts"
                              color="primary"
                              [(ngModel)]="item.checked"
                              (change)="item.checked=$event.checked">
                  {{item.name}}
                </mat-checkbox>
              </div>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="elevations.length" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ 'elevations' | transloco }} {{selectedElevations.length}}/{{elevations.length}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="items">
                <mat-checkbox *ngFor="let item of elevations"
                              color="primary"
                              [(ngModel)]="item.checked"
                              (change)="item.checked=$event.checked">
                  {{item.name}}
                </mat-checkbox>
              </div>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="markers.length" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ 'markers' | transloco }} {{selectedMarkers.length}}/{{markers.length}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="items">
                <mat-checkbox *ngFor="let item of markers"
                              color="primary"
                              [(ngModel)]="item.checked"
                              (change)="item.checked=$event.checked">
                  {{item.name}}
                </mat-checkbox>
              </div>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="measures.length" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ 'measures' | transloco }} {{selectedMeasures.length}}/{{measures.length}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent class="items">
              <div class="items">
                <mat-checkbox *ngFor="let item of measures"
                              color="primary"
                              [(ngModel)]="item.checked"
                              (change)="item.checked=$event.checked">
                  {{item.name}}
                </mat-checkbox>
              </div>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="detections.length" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ 'detections' | transloco }} {{selectedDetections.length}}/{{detections.length}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="items">
                <mat-checkbox *ngFor="let item of detections"
                              color="primary"
                              [(ngModel)]="item.checked"
                              (change)="item.checked=$event.checked">
                  {{item.name}}
                </mat-checkbox>
              </div>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="volumes.length" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ 'volumes' | transloco }} {{selectedVolumes.length}}/{{volumes.length}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="items">
                <mat-checkbox *ngFor="let item of volumes"
                              color="primary"
                              [(ngModel)]="item.checked"
                              (change)="item.checked=$event.checked">
                  <div class="volume-row">
                    <div style="display: flex; flex-direction: row">
                      <div style="width: 5px;" [style.background-color]="item.hexColor">&nbsp;</div>
                      <div>{{item.name}}</div>
                    </div>
                    <div>{{item.volume | number:'1.2-2'}} m3</div>
                  </div>
                </mat-checkbox>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-step>


      <mat-step>
        <ng-template matStepLabel>Types</ng-template>
        <div style="display: flex; flex-direction: column">
          <section class="example-section">
            <span class="example-list-section">
              <mat-checkbox
                class="example-margin"
                [checked]="globalReportTask().completed"
                [indeterminate]="globalReportTaskPartiallyComplete()"
                (change)="update($event.checked)"
              >
                {{globalReportTask().name}}
              </mat-checkbox>
            </span>
            <span class="example-list-section">
              <ul>
                @for (subtask of globalReportTask().subtasks; track subtask; let i = $index) {
                <li>
                  <mat-checkbox [checked]="subtask.completed" (change)="update($event.checked, i)">
                      {{subtask.name}}
                    </mat-checkbox>
                  </li>
                }
              </ul>
            </span>
          </section>
        </div>
      </mat-step>

    </mat-stepper>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button *ngIf="stepper.selectedIndex > 0" (click)="onClickBack()">Back</button>
    <button mat-button *ngIf="stepper.selectedIndex < 1" (click)="onClickNext()">Next</button>
    <button mat-button
            *ngIf="stepper.selectedIndex == 1"
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close()">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
